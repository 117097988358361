// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1syJRZhuS-h0l5OHsiOYRc {\n  border-radius: 50%;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 32px;\n  height: 32px;\n  user-select: none;\n}\n._1syJRZhuS-h0l5OHsiOYRc > img {\n  background-color: white;\n  max-width: 32px;\n  max-height: 32px;\n}\n", "",{"version":3,"sources":["webpack://src/common/components/logo/_styles.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AACF;AAVA;EAYI,uBAAA;EACA,eAAA;EACA,gBAAA;AACJ","sourcesContent":[".logo {\n  border-radius: 50%;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 32px;\n  height: 32px;\n  user-select: none;\n\n  > img {\n    background-color: white;\n    max-width: 32px;\n    max-height: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "_1syJRZhuS-h0l5OHsiOYRc"
};
export default ___CSS_LOADER_EXPORT___;
