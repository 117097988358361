// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2AlK4dCF19TolukqGn0451 {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  user-select: none;\n}\n._2AlK4dCF19TolukqGn0451 ._3jjT2Uh1JFpQ7pyA5kTV8_ {\n  font-family: \"Comfortaa\", cursive;\n  font-size: 14pt;\n  font-weight: normal;\n  opacity: 80%;\n}\n._2AlK4dCF19TolukqGn0451 ._3rzdQTq1S4ENbV9lbxDsPt {\n  font-family: \"Comfortaa\", cursive;\n  font-size: 14pt;\n  font-weight: normal;\n  opacity: 90%;\n}\n._2AlK4dCF19TolukqGn0451 ._2UxF5gEQDWId-QGRuBHLhF {\n  vertical-align: bottom;\n  height: 32px;\n  font-family: \"Comfortaa\", cursive;\n  font-size: 10pt;\n  font-weight: normal;\n  opacity: 80%;\n  min-width: 32px;\n}\n._2AlK4dCF19TolukqGn0451.Ge0Hjmd-qkwUIqSG-Rq_4 ._3jjT2Uh1JFpQ7pyA5kTV8_,\n._2AlK4dCF19TolukqGn0451.Ge0Hjmd-qkwUIqSG-Rq_4 ._3rzdQTq1S4ENbV9lbxDsPt,\n._2AlK4dCF19TolukqGn0451.Ge0Hjmd-qkwUIqSG-Rq_4 ._2UxF5gEQDWId-QGRuBHLhF {\n  color: white;\n}\n", "",{"version":3,"sources":["webpack://src/common/components/brand/_styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,iBAAA;AACF;AALA;EAOI,iCAAA;EACA,eAAA;EACA,mBAAA;EACA,YAAA;AACJ;AAXA;EAcI,iCAAA;EACA,eAAA;EACA,mBAAA;EACA,YAAA;AAAJ;AAjBA;EAqBI,sBAAA;EACA,YAAA;EACA,iCAAA;EACA,eAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;AADJ;AAKA;;;EAEI,YAAA;AAFJ","sourcesContent":[".brand {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  user-select: none;\n\n  .cloud {\n    font-family: \"Comfortaa\", cursive;\n    font-size: 14pt;\n    font-weight: normal;\n    opacity: 80%;\n  }\n\n  .manager {\n    font-family: \"Comfortaa\", cursive;\n    font-size: 14pt;\n    font-weight: normal;\n    opacity: 90%;\n  }\n\n  .version {\n    vertical-align: bottom;\n    height: 32px;\n    font-family: \"Comfortaa\", cursive;\n    font-size: 10pt;\n    font-weight: normal;\n    opacity: 80%;\n    min-width: 32px;\n  }\n}\n\n.brand.dark {\n  .cloud, .manager, .version {\n    color: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brand": "_2AlK4dCF19TolukqGn0451",
	"cloud": "_3jjT2Uh1JFpQ7pyA5kTV8_",
	"manager": "_3rzdQTq1S4ENbV9lbxDsPt",
	"version": "_2UxF5gEQDWId-QGRuBHLhF",
	"dark": "Ge0Hjmd-qkwUIqSG-Rq_4"
};
export default ___CSS_LOADER_EXPORT___;
