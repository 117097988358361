// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._27SVDglloSBTbKXnejV1wf > * {\n  display: block;\n}\n.TXJgG_ziDSiLfnSDWRCgT {\n  padding-left: 128px;\n  margin-left: 14px;\n}\n._3lyEJsixzsnYXVl22Y7wxY {\n  min-width: 90px;\n  text-align: right;\n  font-weight: bold;\n}\n._3lyEJsixzsnYXVl22Y7wxY > small {\n  margin-left: 4px;\n}\n._3pbrb3mmQuo5e1KBleLLcK {\n  padding-left: 80px;\n}\n", "",{"version":3,"sources":["webpack://src/features/instaladores/styles.less"],"names":[],"mappings":"AAAA;EAEI,cAAA;AAAJ;AAIA;EACE,mBAAA;EACA,iBAAA;AAFF;AAKA;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;AAHF;AAAA;EAMI,gBAAA;AAHJ;AAOA;EACE,kBAAA;AALF","sourcesContent":[".checkbox-group {\n  > * {\n    display: block;\n  }\n}\n\n.footer {\n  padding-left: 128px;\n  margin-left: 14px;\n}\n\n.artefato {\n  min-width: 90px;\n  text-align: right;\n  font-weight: bold;\n\n  > small {\n    margin-left: 4px;\n  }\n}\n\n.tail-item {\n  padding-left: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox-group": "_27SVDglloSBTbKXnejV1wf",
	"footer": "TXJgG_ziDSiLfnSDWRCgT",
	"artefato": "_3lyEJsixzsnYXVl22Y7wxY",
	"tail-item": "_3pbrb3mmQuo5e1KBleLLcK"
};
export default ___CSS_LOADER_EXPORT___;
