// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1uflb_sL5mmKGH6xHRjXyM > * {\n  display: block;\n}\n._3ZvGhvU5Yj2aoVP1GHmglK {\n  padding-left: 80px;\n}\n._1wq0jP_8cqnGCMuzJsN1El {\n  padding-left: 80px;\n  margin-left: 14px;\n}\n", "",{"version":3,"sources":["webpack://src/features/usuarios/styles.less"],"names":[],"mappings":"AAAA;EAEI,cAAA;AAAJ;AAIA;EACE,kBAAA;AAFF;AAKA;EACE,kBAAA;EACA,iBAAA;AAHF","sourcesContent":[".checkbox-group {\n  > * {\n    display: block;\n  }\n}\n\n.tail-item {\n  padding-left: 80px;\n}\n\n.footer {\n  padding-left: 80px;\n  margin-left: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox-group": "_1uflb_sL5mmKGH6xHRjXyM",
	"tail-item": "_3ZvGhvU5Yj2aoVP1GHmglK",
	"footer": "_1wq0jP_8cqnGCMuzJsN1El"
};
export default ___CSS_LOADER_EXPORT___;
