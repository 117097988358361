import {ApiOutlined, CodeOutlined, DatabaseOutlined, FolderOpenOutlined, MonitorOutlined, TeamOutlined, ToolOutlined} from '@ant-design/icons';
import React from 'react';
import Empresas from '../../features/empresas/page';
import Ferramentas from '../../features/ferramentas/page';
import Ftp from '../../features/ftp/page';
import Instaladores from '../../features/instaladores/page';
import MonitorServicos from '../../features/monitor-servicos/page';
import Servicos from '../../features/servicos/page';
import Usuarios from '../../features/usuarios/page';

const routes = [
  {key: '/ftp', path: '/ftp', roles: ['ftp'], drawerTitle: 'FTP', icon: <FolderOpenOutlined />, component: Ftp},
  {key: '/empresas', path: '/empresas/:id?', roles: ['empresas'], drawerTitle: 'Empresas', icon: <DatabaseOutlined />, component: Empresas},
  {key: '/monitor-servicos', path: '/monitor-servicos', roles: ['monitor-servicos'], drawerTitle: 'Monitor de Serviços', icon: <MonitorOutlined />, component: MonitorServicos},
  {key: '/ferramentas', path: ['/ferramentas/:id/artefatos/:artefatoId', '/ferramentas/:id?'], roles: ['ferramentas'], drawerTitle: 'Ferramentas', icon: <ToolOutlined />, component: Ferramentas},
  {key: '/instaladores', path: ['/instaladores/:id/artefatos/:artefatoId', '/instaladores/:id?'], roles: ['instaladores'], drawerTitle: 'Instaldores', icon: <CodeOutlined />, component: Instaladores},
  {key: '/servicos', path: '/servicos/:id?', roles: ['servicos'], drawerTitle: 'Serviços', icon: <ApiOutlined />, component: Servicos},
  {key: '/usuarios', path: '/usuarios/:id?', roles: ['usuarios'], drawerTitle: 'Usuários', icon: <TeamOutlined />, component: Usuarios},
];

export default routes;
