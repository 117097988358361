import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Route, Switch} from 'react-router';
import {Router} from 'react-router-dom';
import history from '../history';
import Layout from '../layout';
import Blank from '../pages/blank';
import Entrar from '../pages/entrar';
import Loading from '../pages/loading';
import NovaSenha from '../pages/nova-senha';
import RecuperSenha from '../pages/recuperar-senha';
import {init} from '../reducers/app';
import {userRolesSelector} from '../reducers/auth';
import {useLoading} from '../reducers/loading';
import routes from './routes';

export default function() {
  const userRoles = useSelector(userRolesSelector);

  const authorizedRoutes = useMemo(() => routes
          .filter(route => route.roles.every(r => userRoles.includes(r))),
      [userRoles]);

  const loading = useLoading(init.typePrefix);

  if (loading)
    return <Loading />;

  return (
      <Router history={history}>
        <Switch>
          <Route path="/entrar" exact component={Entrar} />
          <Route path="/recuperar-senha" exact component={RecuperSenha} />
          <Route path="/nova-senha" exact component={NovaSenha} />
          <Route path="*">
            <Layout routes={authorizedRoutes}>
              <Switch>
                {authorizedRoutes.map(({key, path, exact, component}) => <Route key={key} path={path} exact={exact} component={component} />)}
                <Route path="*" component={Blank} />
              </Switch>
            </Layout>
          </Route>
        </Switch>
      </Router>
  );
}
