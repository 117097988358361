import {LockOutlined, LoginOutlined, UserOutlined} from '@ant-design/icons';
import {Alert, Button, Card, Checkbox, Form, Input, Layout} from 'antd';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import Brand from '../components/brand';
import Link from '../components/link';
import Logo from '../components/logo';
import useDispatchWithResult from '../hooks/useDispatchWithResult';
import SimpleLayout from '../layout/simple';
import {emailChanged, emailSelector, lembrarSelector, login} from '../reducers/auth';
import {useLoading} from '../reducers/loading';
import {unwrapResult} from '../store';
import styles from './_styles.less';

const rules = {
  email: [{required: true, type: 'email'}],
  senha: [{required: true}],
};

const formLayout = {
  layout: 'horizontal',
  labelCol: {flex: '64px'},
  wrapperCol: {flex: 'auto'},
};

function Cover() {
  return (
      <Layout.Header className={styles['cover']}>
        <div><Logo /></div>
        <div><Brand dark /></div>
      </Layout.Header>
  );
}

const buttonStyle = {paddingLeft: '64px'};

export default function() {
  const [error, setError] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatchWithResult();
  const loading = useLoading(login.typePrefix);
  const history = useHistory();
  const email = useSelector(emailSelector);
  const lembrar = useSelector(lembrarSelector);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({email, senha: '', lembrar});
  }, [email, lembrar]);

  const handleValuesChange = changedValues => {
    const {email} = changedValues;
    if (email !== undefined)
      dispatch(emailChanged(email));
  };

  const handleFinish = payload => {
    setError(false);
    dispatch(login(payload), form)
        .then(unwrapResult)
        .then(() => history.push('/'))
        .catch(err => setError(err.message));
  };

  const alert = error ?
      <Alert showIcon message={error} type="error" className={styles['alert']} /> :
      <Alert showIcon message="Autenticação" type="info" className={styles['alert']} />;

  return (
      <SimpleLayout>
        <div className={styles['centered']}>
          <Card cover={<Cover />} className={styles['card']} bordered={false}>
            <Form
                form={form}
                onValuesChange={handleValuesChange}
                onFinish={handleFinish}
                {...formLayout} >
              {alert}
              <Form.Item name="email" label="E-mail" rules={rules.email}>
                <Input size="middle" prefix={<UserOutlined className={styles['prefix']} />} maxLength={100} autoFocus disabled={loading} />
              </Form.Item>
              <Form.Item name="senha" label="Senha" rules={rules.senha}>
                <Input.Password size="middle" prefix={<LockOutlined className={styles['prefix']} />} maxLength={32} disabled={loading} />
              </Form.Item>
              <Form.Item name="lembrar" style={buttonStyle} valuePropName="checked">
                <Checkbox>Lembrar do meu e-mail</Checkbox>
              </Form.Item>
              <Form.Item style={buttonStyle}>
                <Button size="middle" icon={<LoginOutlined />} type="primary" htmlType="submit" loading={loading}>Entrar</Button>
                <span className={styles['link']}>
                  <Link to="/recuperar-senha" disabled={loading}>recuperar senha</Link>
                </span>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </SimpleLayout>
  );
};
