import {createSlice} from '@reduxjs/toolkit';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';

export const slice = createSlice({
  name: 'errors',
  initialState: {},
  reducers: {
    clear(state, action) {
      for (const typePrefix of action.payload)
        state[typePrefix] = false;
    },
  },
  extraReducers: builder => {
    builder
        .addMatcher(
            action => action.type.endsWith('/pending'),
            (state, action) => {
              const typePrefix = action.type.substring(0, action.type.length - 8);
              state[typePrefix] = false;
            })
        .addMatcher(
            action => action.type.endsWith('/rejected'),
            (state, action) => {
              const typePrefix = action.type.substring(0, action.type.length - 9);
              state[typePrefix] = action.payload;
            },
        );
  },
});

export const {clear: clearErrors} = slice.actions;

export function useAllErrors() {
  return useSelector(state => Object.values(state.errors));
}

export function useErrors() {
  const types = useMemo(() => Array.from(arguments), [arguments]);
  return useSelector(
      state => Object.entries(state.errors)
          .filter(([_, errors]) => errors !== false)
          .filter(([typePrefix]) => types.includes(typePrefix)))
      .map(([_, errors]) => errors);
}

export default slice.reducer;
