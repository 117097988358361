import {createAsyncThunk, createSelector, createSlice} from '@reduxjs/toolkit';
import api from '../../common/api';
import {parseMoment} from '../../common/utils/parser';
import {compare, contains} from '../../common/utils/text';

const initialState = {
  byId: {},
  search: '',
  selectedRowKeys: [],
  usuario: false,
  papeis: {
    byId: {
      'empresas': {id: 'empresas', nome: 'Empresas'},
      'ferramentas': {id: 'ferramentas', nome: 'Ferramentas'},
      'ftp': {id: 'ftp', nome: 'FTP'},
      'instaladores': {id: 'instaladores', nome: 'Instaladores'},
      'monitor-servicos': {id: 'monitor-servicos', nome: 'Monitor de Serviços'},
      'servicos': {id: 'servicos', nome: 'Serviços'},
      'usuarios': {id: 'usuarios', nome: 'Usuários'},
    },
  },
};

export const byIdSelector = state => state.usuarios.byId;
export const searchSelector = state => state.usuarios.search;
export const selectedRowKeysSelector = state => state.usuarios.selectedRowKeys;
export const usuarioSelector = state => state.usuarios.usuario;
export const papeisByIdSelector = state => state.usuarios.papeis.byId;

export const dataSourceSelector = createSelector(
    byIdSelector,
    searchSelector,
    (byId, search) => {
      const words = search.split(/\s+/).map(value => value.trim()).filter(value => value.length > 0);
      let values = Object.values(byId);
      if (words.length > 0)
        values = values.filter(row => {
          loop:
              for (const word of words) {
                if (contains(row.email, word)) continue;
                for (const papelFormatado of row.papeisFormatados)
                  if (contains(papelFormatado, word)) continue loop;
                return false;
              }
          return true;
        });
      return values.sort((a, b) => compare(a.email, b.email));
    },
);

export const usuarioFormSelector = createSelector(
    usuarioSelector,
    usuario =>
        usuario ? ({
          ...usuario,
          dataCriacao: parseMoment(usuario.dataCriacao),
          dataSenha: parseMoment(usuario.dataSenha),
        }) : {},
);

export const papeisOptionsSelector = createSelector(
    papeisByIdSelector,
    byId => Object.values(byId)
        .map(papel => ({label: papel.nome, value: papel.id}))
        .sort((a, b) => compare(a.label, b.label)),
);

export const fetch = createAsyncThunk(
    'usuarios/fetch',
    async (_, thunkAPI) => {
      const res = await api.get('/usuarios');
      return res.data;
    },
);

export const removeSelectedRowKeys = createAsyncThunk(
    'usuarios/removeSelectedRowKeys',
    async (_, thunkAPI) => {
      try {
        const {selectedRowKeys} = thunkAPI.getState().usuarios;
        await Promise.all(selectedRowKeys
            .map(id => api.delete(`/usuarios/${id}`)));
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const fetchOne = createAsyncThunk(
    'usuarios/fetchOne',
    async (id, thunkAPI) => {
      const res = await api.get(`/usuarios/${id}`);
      return res.data;
    },
);

export const updateOne = createAsyncThunk(
    'usuarios/updateOne',
    async (payload, thunkAPI) => {
      try {
        const {id, ...usuario} = payload;
        await api.put(`/usuarios/${id}`, usuario);
        await thunkAPI.dispatch(fetchOne(id));
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const insertOne = createAsyncThunk(
    'usuarios/insertOne',
    async (payload, thunkAPI) => {
      try {
        const res = await api.post(`/usuarios`, payload);
        await thunkAPI.dispatch(fetchOne(res.data.id));
        return res.data;
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const slice = createSlice({
  name: 'usuarios',
  initialState,
  reducers: {
    clear: () => initialState,
    search: (state, action) => {
      state.search = action.payload;
    },
    onRowSelectionChange: (state, action) => {
      state.selectedRowKeys = action.payload;
    },
    clearOne: (state) => {
      state.usuario = false;
    },
    newOne: (state) => {
      state.usuario = {
        email: '',
        ativo: false,
        papeis: [],
      };
    },
  },
  extraReducers: {
    [fetch.fulfilled]: (state, action) => {
      state.byId = action.payload.reduce((pv, cv) => {
        pv[cv.id] = mapUsuario(state, cv);
        return pv;
      }, {});
    },
    [removeSelectedRowKeys.fulfilled]: (state, action) => {
      state.selectedRowKeys.forEach(id => delete state.byId[id]);
      state.selectedRowKeys = [];
    },
    [fetchOne.fulfilled]: (state, action) => {
      const {id, email, ativo, papeis} = action.payload;
      state.usuario = {...initialState.data, ...action.payload};
      state.byId[action.payload.id] = mapUsuario(state, {id, email, ativo, papeis});
    },
  },
});

export const {clear, search, onRowSelectionChange, clearOne, newOne} = slice.actions;

export default slice.reducer;

function mapUsuario(state, usuario) {
  return {
    ...usuario,
    papeisFormatados: usuario.papeis.map(papel => state.papeis.byId[papel].nome),
  };
}
