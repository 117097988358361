import {createAsyncThunk, createSelector, createSlice} from '@reduxjs/toolkit';
import api from '../../common/api';
import {parseMoment} from '../../common/utils/parser';
import {compare, contains} from '../../common/utils/text';

const initialState = {
  byId: {},
  search: '',
  selectedRowKeys: [],
  ferramenta: false,
};

export const byIdSelector = state => state.ferramentas.byId;
export const searchSelector = state => state.ferramentas.search;
export const selectedRowKeysSelector = state => state.ferramentas.selectedRowKeys;
export const ferramentaSelector = state => state.ferramentas.ferramenta;

export const dataSourceSelector = createSelector(
    byIdSelector,
    searchSelector,
    (byId, search) => {
      const words = search.split(/\s+/).map(value => value.trim()).filter(value => value.length > 0);
      let values = Object.values(byId);
      if (words.length > 0)
        values = values
            .filter(row => {
              loop:
                  for (const word of words) {
                    if (contains(row.nome, word)) continue;
                    for (const artefato of row.artefatos) {
                      if (contains(artefato.arquitetura, word)) continue loop;
                      if (contains(artefato.versao, word)) continue loop;
                    }
                    return false;
                  }
              return true;
            });
      return values.sort((a, b) => compare(a.nome, b.nome));
    },
);

export const ferramentaFormSelector = createSelector(
    ferramentaSelector,
    ferramenta =>
        ferramenta ? ({
          ...ferramenta,
          dataCriacao: parseMoment(ferramenta.dataCriacao),
          dataAtualizacao: parseMoment(ferramenta.dataAtualizacao),
        }) : {},
);

export const fetch = createAsyncThunk(
    'ferramentas/fetch',
    async (_, thunkAPI) => {
      const res = await api.get('/ferramentas');
      return res.data;
    },
);

export const removeSelectedRowKeys = createAsyncThunk(
    'ferramentas/removeSelectedRowKeys',
    async (_, thunkAPI) => {
      try {
        const {selectedRowKeys} = thunkAPI.getState().ferramentas;
        await Promise.all(selectedRowKeys
            .map(id => api.delete(`/ferramentas/${id}`)));
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const fetchOne = createAsyncThunk(
    'ferramentas/fetchOne',
    async (id, thunkAPI) => {
      const res = await api.get(`/ferramentas/${id}`);
      return res.data;
    },
);

export const updateOne = createAsyncThunk(
    'ferramentas/updateOne',
    async (payload, thunkAPI) => {
      try {
        const {id, ...ferramenta} = payload;
        await api.put(`/ferramentas/${id}`, ferramenta);
        await thunkAPI.dispatch(fetchOne(id));
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const insertOne = createAsyncThunk(
    'ferramentas/insertOne',
    async (payload, thunkAPI) => {
      try {
        const res = await api.post(`/ferramentas`, payload);
        await thunkAPI.dispatch(fetchOne(res.data.id));
        return res.data;
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const slice = createSlice({
  name: 'ferramentas',
  initialState,
  reducers: {
    clear: () => initialState,
    search: (state, action) => {
      state.search = action.payload;
    },
    onRowSelectionChange: (state, action) => {
      state.selectedRowKeys = action.payload;
    },
    clearOne: (state) => {
      state.ferramenta = false;
    },
    newOne: (state) => {
      state.ferramenta = {
        nome: '',
        publicado: false,
      };
    },
    clearArtefatos: state => {
      state.artefatos.byId = {};
    },
    onRowSelectionArtefatosChange: (state, action) => {
      state.artefatos.selectedRowKeys = action.payload;
    },
  },
  extraReducers: {
    [fetch.fulfilled]: (state, action) => {
      state.byId = action.payload.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {});
    },
    [removeSelectedRowKeys.fulfilled]: (state, action) => {
      state.selectedRowKeys.forEach(id => delete state.byId[id]);
      state.selectedRowKeys = [];
    },
    [fetchOne.fulfilled]: (state, action) => {
      const {id, nome, artefatos = [], publicado} = action.payload;
      state.ferramenta = {...initialState.ferramenta, ...action.payload};
      state.byId[action.payload.id] = {...state.byId[action.payload.id], id, nome, artefatos, publicado};
    },
  },
});

export const {clear, search, onRowSelectionChange, clearOne, newOne} = slice.actions;

export default slice.reducer;
