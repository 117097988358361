import {useEffect} from 'react';

export function useDebouncedEffect(ms, effect, deps) {
  useEffect(() => {
    let result;
    const timeout = setTimeout(() => result = effect(), ms);
    return () => {
      clearTimeout(timeout);
      if (typeof result === 'function')
        result();
    };
  }, deps);
}
