// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1NNfRePhgZ4ptYCI0gW9Uz > * {\n  display: block;\n}\n._2LJzqg9JYUkMT93UxZxEka {\n  padding-left: 128px;\n  margin-left: 14px;\n}\n", "",{"version":3,"sources":["webpack://src/features/empresas/styles.less"],"names":[],"mappings":"AAAA;EAEI,cAAA;AAAJ;AAIA;EACE,mBAAA;EACA,iBAAA;AAFF","sourcesContent":[".checkbox-group {\n  > * {\n    display: block;\n  }\n}\n\n.footer {\n  padding-left: 128px;\n  margin-left: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox-group": "_1NNfRePhgZ4ptYCI0gW9Uz",
	"footer": "_2LJzqg9JYUkMT93UxZxEka"
};
export default ___CSS_LOADER_EXPORT___;
