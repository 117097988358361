import {CheckOutlined, DeleteOutlined, PlusOutlined, QuestionCircleTwoTone} from '@ant-design/icons';
import {Button, message, Popconfirm} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useHistory} from 'react-router';

function ActionButtonNew({to, ...props}) {
  const history = useHistory();
  const handleClick = () => history.push(to);
  return (
      <ActionButton
          type="primary"
          onClick={handleClick}
          title="Novo"
          icon={<PlusOutlined />}
          {...props}
      />
  );
}

ActionButtonNew.propTypes = {
  to: PropTypes.string.isRequired,
};

function ActionButtonRemove({onClick, ...props}) {
  const handleConfirm = () => {
    if (typeof onClick === 'function') {
      const result = onClick();
      if (result instanceof Promise)
        result.catch(err => message.error(err.message));
    }
  };

  return (
      <Popconfirm
          title="Você confirma a exclusão do(s) registro(s) selecionado(s)?"
          onConfirm={handleConfirm}
          okText="Sim, excluir"
          cancelText="Não excluir"
          okButtonProps={{type: 'danger'}}
          icon={<QuestionCircleTwoTone twoToneColor="#ff4d4f" />}
      >
        <ActionButton
            type="danger"
            title="Excluir"
            icon={<DeleteOutlined />}
            {...props}
        />
      </Popconfirm>
  );
}

ActionButtonNew.propTypes = {
  onClick: PropTypes.func,
};

function ActionButtonSave({...props}) {
  return (
      <ActionButton
          type="primary"
          title="Salvar"
          icon={<CheckOutlined />}
          {...props}
      />
  );
}

ActionButtonSave.propTypes = {
  onClick: PropTypes.func,
};

function ActionButtonClose({...props}) {
  return (
      <ActionButton
          title="Fechar"
          {...props}
      />
  );
}

ActionButtonClose.propTypes = {
  onClick: PropTypes.func,
};

function ActionButton({disabled, loading, onClick, primary, title, ...props}) {
  const type = primary ? 'primary' : undefined;
  const handleClick = e => {
    if (typeof onClick === 'function') {
      e.preventDefault();
      onClick();
    }
  };
  return (
      <Button
          disabled={disabled}
          loading={loading}
          onClick={handleClick}
          type={type}
          children={title}
          {...props}
      />
  );
}

ActionButton.defaultProps = {
  disabled: false,
  loading: false,
  primary: false,
};

ActionButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

ActionButton.New = ActionButtonNew;
ActionButton.Remove = ActionButtonRemove;
ActionButton.Save = ActionButtonSave;
ActionButton.Close = ActionButtonClose;

export default ActionButton;
