import {Input} from 'antd';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useDebouncedEffect} from '../../hooks/useDebouncedEffect';
import styles from './_styles.less';

function Search({onSearch, loading}) {
  const [searchText, setSearchText] = useState('');

  useDebouncedEffect(200, () => {
    if (onSearch) onSearch(searchText);
  }, [searchText]);

  return (
      <Input.Search
          className={styles['search']}
          onChange={e => setSearchText(e.target.value)}
          loading={loading}
          autoFocus
      />
  );
}

Search.propTypes = {
  onSearch: PropTypes.func,
  loading: PropTypes.bool,
};

export default Search;
