import axios from 'axios';

let accessToken = localStorage.getItem('access-token');

const api = axios.create({baseURL: '/manager/api/manager'});
api.interceptors.request.use(
    config => {
      if (accessToken)
        config.headers['authorization'] = `Bearer ${accessToken}`;
      return config;
    });
api.interceptors.response.use(
    response => response,
    error => {
      // if (error?.response?.status === 401) {
      //   return Promise.reject(error.response.data);
      // }
      if (error?.response?.data)
        return Promise.reject(error.response.data);
      return Promise.reject(error);
    });

export function setAccessToken(newAccessToken) {
  accessToken = newAccessToken;
  localStorage.setItem('access-token', accessToken);
}

export default api;
