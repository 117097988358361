// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3WRuXTkJll-m4yt_aA8Gsn {\n  margin-bottom: 8px;\n}\n._3PFRhcvBjxCP7NAF-kj0-j {\n  font-size: 18pt;\n}\n", "",{"version":3,"sources":["webpack://src/common/components/mini-upload/_styles.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAEA;EACE,eAAA;AAAF","sourcesContent":[".dragger {\n  margin-bottom: 8px;\n}\n\n.icon {\n  font-size: 18pt;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dragger": "_3WRuXTkJll-m4yt_aA8Gsn",
	"icon": "_3PFRhcvBjxCP7NAF-kj0-j"
};
export default ___CSS_LOADER_EXPORT___;
