// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1asrM1EjW7V7qAVz4DsDqz > * {\n  display: block;\n}\n._11-VykqaPL_Pyl8wP5n_Un {\n  padding-left: 128px;\n  margin-left: 14px;\n}\n._3p74HAgPnlfE3Lms9DEHqR {\n  min-width: 90px;\n  text-align: right;\n  font-weight: bold;\n}\n._3p74HAgPnlfE3Lms9DEHqR > small {\n  margin-left: 4px;\n}\n._3p74HAgPnlfE3Lms9DEHqR:hover {\n  cursor: pointer;\n}\n._1OfK_biRdtemc1kUTph1Bo {\n  padding-left: 80px;\n}\n._3tO6sGUdKyJejD-IJTgUDM {\n  font-size: 18pt;\n}\n", "",{"version":3,"sources":["webpack://src/features/ferramentas/artefatos/styles.less"],"names":[],"mappings":"AAAA;EAEI,cAAA;AAAJ;AAIA;EACE,mBAAA;EACA,iBAAA;AAFF;AAKA;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;AAHF;AAAA;EAMI,gBAAA;AAHJ;AAME;EACE,eAAA;AAJJ;AAQA;EACE,kBAAA;AANF;AASA;EACE,eAAA;AAPF","sourcesContent":[".checkbox-group {\n  > * {\n    display: block;\n  }\n}\n\n.footer {\n  padding-left: 128px;\n  margin-left: 14px;\n}\n\n.artefato {\n  min-width: 90px;\n  text-align: right;\n  font-weight: bold;\n\n  > small {\n    margin-left: 4px;\n  }\n\n  &:hover {\n    cursor: pointer;\n  }\n}\n\n.tail-item {\n  padding-left: 80px;\n}\n\n.dragger-icon {\n  font-size: 18pt;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox-group": "_1asrM1EjW7V7qAVz4DsDqz",
	"footer": "_11-VykqaPL_Pyl8wP5n_Un",
	"artefato": "_3p74HAgPnlfE3Lms9DEHqR",
	"tail-item": "_1OfK_biRdtemc1kUTph1Bo",
	"dragger-icon": "_3tO6sGUdKyJejD-IJTgUDM"
};
export default ___CSS_LOADER_EXPORT___;
