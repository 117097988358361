import {createAsyncThunk, createSelector, createSlice} from '@reduxjs/toolkit';
import api from '../../common/api';
import {parseMoment} from '../../common/utils/parser';
import {contains} from '../../common/utils/text';

const initialState = {
  byId: {},
  search: '',
  selectedRowKeys: [],
  servico: false,
};

export const byIdSelector = state => state.servicos.byId;
export const searchSelector = state => state.servicos.search;
export const selectedRowKeysSelector = state => state.servicos.selectedRowKeys;
export const servicoselector = state => state.servicos.servico;

function getTipoNome(tipo) {
  switch (tipo) {
    case 'netcoreapp2.1':
      return 'NetCoreApp 2.1';
    case 'dotnet':
      return 'DotNet';
    case 'java':
      return 'JRE 8';
    case 'jre11':
      return 'JRE 11';
    case 'nginx':
      return 'Nginx';
  }
  return tipo;
}

export const dataSourceSelector = createSelector(
    byIdSelector,
    searchSelector,
    (byId, search) => {
      const words = search.split(/\s+/).map(value => value.trim()).filter(value => value.length > 0);
      let values = Object.values(byId)
          .map(row => ({
            ...row,
            tipoNome: getTipoNome(row.tipo),
          }));
      if (words.length > 0)
        values = values
            .filter(row => {
              for (const word of words) {
                if (contains(row.nome, word)) continue;
                if (contains(row.versao, word)) continue;
                if (contains(row.tipoNome, word)) continue;
                return false;
              }
              return true;
            });
      return values.sort((a, b) => a._index - b._index);
    },
);

export const servicoFormSelector = createSelector(
    servicoselector,
    servico =>
        servico ? ({
          ...servico,
          dataCriacao: parseMoment(servico.dataCriacao),
          dataAtualizacao: parseMoment(servico.dataAtualizacao),
        }) : {},
);

export const fetch = createAsyncThunk(
    'servicos/fetch',
    async (_, thunkAPI) => {
      const res = await api.get('/servicos');
      return res.data;
    },
);

export const removeSelectedRowKeys = createAsyncThunk(
    'servicos/removeSelectedRowKeys',
    async (_, thunkAPI) => {
      try {
        const {selectedRowKeys} = thunkAPI.getState().servicos;
        await Promise.all(selectedRowKeys
            .map(id => api.delete(`/servicos/${id}`)));
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const fetchOne = createAsyncThunk(
    'servicos/fetchOne',
    async (id, thunkAPI) => {
      const res = await api.get(`/servicos/${id}`);
      return res.data;
    },
);

export const updateOne = createAsyncThunk(
    'servicos/updateOne',
    async (payload, thunkAPI) => {
      try {
        const {id, ...servico} = payload;
        await api.put(`/servicos/${id}`, servico);
        await thunkAPI.dispatch(fetchOne(id));
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const insertOne = createAsyncThunk(
    'servicos/insertOne',
    async (payload, thunkAPI) => {
      try {
        const formData = new FormData();
        formData.append('arquivo', payload.arquivo);
        formData.append('nome', payload.nome);
        formData.append('versao', payload.versao);
        formData.append('tipo', payload.tipo);
        formData.append('publicado', payload.publicado);
        const headers = {};
        headers['content-type'] = 'multipart/form-data';
        const res = await api.post(`/servicos`, formData, {headers});
        await thunkAPI.dispatch(fetchOne(res.data.id));
        return res.data;
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const slice = createSlice({
  name: 'servicos',
  initialState,
  reducers: {
    clear: () => initialState,
    search: (state, action) => {
      state.search = action.payload;
    },
    onRowSelectionChange: (state, action) => {
      state.selectedRowKeys = action.payload;
    },
    clearOne: (state) => {
      state.servico = false;
    },
    newOne: (state) => {
      state.servico = {
        nome: '',
        publicado: false,
      };
    },
    clearArtefatos: state => {
      state.artefatos.byId = {};
    },
    onRowSelectionArtefatosChange: (state, action) => {
      state.artefatos.selectedRowKeys = action.payload;
    },
  },
  extraReducers: {
    [fetch.fulfilled]: (state, action) => {
      state.byId = action.payload.reduce((pv, cv, index) => {
        pv[cv.id] = {_index: index, ...cv};
        return pv;
      }, {});
    },
    [removeSelectedRowKeys.fulfilled]: (state, action) => {
      state.selectedRowKeys.forEach(id => delete state.byId[id]);
      state.selectedRowKeys = [];
    },
    [fetchOne.fulfilled]: (state, action) => {
      const {id, nome, versao, tipo, publicado} = action.payload;
      state.servico = {...initialState.servico, ...action.payload};
      state.byId[action.payload.id] = {...state.byId[action.payload.id], id, nome, versao, tipo, publicado};
    },
  },
});

export const {clear, search, onRowSelectionChange, clearOne, newOne} = slice.actions;

export default slice.reducer;
