import {createAsyncThunk, createSelector, createSlice} from '@reduxjs/toolkit';
import api from '../../../common/api';
import {parseMoment} from '../../../common/utils/parser';
import {fetchOne as fetchOneFerramenta} from '../reducer';

const initialState = {
  ferramentaId: 0,
  byId: {},
  byVersao: {},
  selectedRowKeys: [],
  artefato: false,
};

export const byIdSelector = state => state.ferramentasArtefatos.byId;
export const byVersaoSelector = state => state.ferramentasArtefatos.byVersao;
export const selectedRowKeysSelector = state => state.ferramentasArtefatos.selectedRowKeys;
export const artefatoSelector = state => state.ferramentasArtefatos.artefato;

export const dataSourceSelector = createSelector(
    byIdSelector,
    byVersaoSelector,
    (byId, byVersao) =>
        Object.entries(byVersao)
            .map(([versao, ids]) => {
              let ativo = false;
              const artefatos = ids
                  .map(id => {
                    const artefato = byId[id];
                    ativo |= artefato.ativo;
                    return artefato;
                  });
              return {versao, ativo, artefatos};
            }, {}),
);

export const artefatoFormSelector = createSelector(
    artefatoSelector,
    artefato =>
        artefato ? ({
          ...artefato,
          dataCriacao: parseMoment(artefato.dataCriacao),
          dataAtualizacao: parseMoment(artefato.dataAtualizacao),
        }) : {},
);

export const fetch = createAsyncThunk(
    'ferramentasArtefatos/fetch',
    async (ferramentaId, thunkAPI) => {
      const res = await api.get(`/ferramentas/${ferramentaId}/artefatos`);
      return res.data;
    },
);

export const removeSelectedRowKeys = createAsyncThunk(
    'ferramentasArtefatos/removeSelectedRowKeys',
    async (_, thunkAPI) => {
      try {
        const {ferramentaId, byVersao, selectedRowKeys} = thunkAPI.getState().ferramentasArtefatos;
        await Promise.all(selectedRowKeys
            .flatMap(versao => byVersao[versao])
            .map(id => api.delete(`/ferramentas/${ferramentaId}/artefatos/${id}`)));
        await thunkAPI.dispatch(fetchOneFerramenta(ferramentaId));
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const fetchOne = createAsyncThunk(
    'ferramentasArtefatos/fetchOne',
    async ({ferramentaId, id}, thunkAPI) => {
      const res = await api.get(`/ferramentas/${ferramentaId}/artefatos/${id}`);
      return res.data;
    },
);

export const updateOne = createAsyncThunk(
    'ferramentasArtefatos/updateOne',
    async (payload, thunkAPI) => {
      try {
        const {ferramentaId} = thunkAPI.getState().ferramentasArtefatos;
        const {id, ...artefato} = payload;
        await api.put(`/ferramentas/${ferramentaId}/artefatos/${id}`, artefato);
        await thunkAPI.dispatch(fetchOne({ferramentaId, id}));
        await thunkAPI.dispatch(fetchOneFerramenta(ferramentaId));
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const insertOne = createAsyncThunk(
    'ferramentasArtefatos/insertOne',
    async (payload, thunkAPI) => {
      try {
        const {ferramentaId} = thunkAPI.getState().ferramentasArtefatos;
        const formData = new FormData();
        formData.append('arquivo', payload.arquivo);
        formData.append('arquiteturas', payload.arquiteturas);
        formData.append('versao', payload.versao);
        formData.append('ativo', payload.ativo);
        const headers = {};
        headers['content-type'] = 'multipart/form-data';
        const res = await api.post(`/ferramentas/${ferramentaId}/artefatos`, formData, {headers});
        const id = res.data.id;
        await thunkAPI.dispatch(fetchOne({ferramentaId, id}));
        await thunkAPI.dispatch(fetch(ferramentaId));
        await thunkAPI.dispatch(fetchOneFerramenta(ferramentaId));
        return res.data;
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const slice = createSlice({
  name: 'ferramentasArtefatos',
  initialState,
  reducers: {
    clear: () => initialState,
    search: (state, action) => {
      state.search = action.payload;
    },
    onRowSelectionChange: (state, action) => {
      state.selectedRowKeys = action.payload;
    },
    clearOne: (state) => {
      state.artefato = false;
    },
    newOne: (state, action) => {
      state.ferramentaId = action.payload.ferramentaId;
      state.artefato = {
        versao: '',
        arquitetura: '',
        ativo: false,
      };
    },
  },
  extraReducers: {
    [fetch.fulfilled]: (state, action) => {
      state.ferramentaId = action.meta.arg;
      const byVersao = {};
      state.byId = action.payload.reduce((pv, cv) => {
        pv[cv.id] = cv;

        if (byVersao.hasOwnProperty(cv.versao))
          byVersao[cv.versao].push(cv.id);
        else
          byVersao[cv.versao] = [cv.id];

        return pv;
      }, {});
      state.byVersao = byVersao;
    },
    [removeSelectedRowKeys.fulfilled]: (state, action) => {
      state.selectedRowKeys.forEach(versao => {
        state.byVersao[versao] = state.byVersao[versao]
            .forEach(id => {
              delete state.byId[id];
            });
        delete state.byVersao[versao];
      });
      state.selectedRowKeys = [];
    },
    [fetchOne.fulfilled]: (state, action) => {
      state.ferramentaId = action.meta.arg.ferramentaId;
      const {id, versao, arquitetura, ativo} = action.payload;
      const arquiteturas = arquitetura ? [arquitetura] : [];
      state.artefato = {...initialState.artefato, ...action.payload, arquiteturas};
      state.byId[id] = {...state.byId[id], id, versao, arquitetura, ativo};
      const byVersao = state.byVersao[versao];
      if (byVersao && !byVersao.some(a => a === id))
        byVersao.push(id);
    },
  },
});

export const {clear, search, onRowSelectionChange, clearOne, newOne} = slice.actions;

export default slice.reducer;
