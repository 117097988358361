import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from '../../common/api';

const initialState = {
  data: {
    url: '',
    usuario: '',
    alterarSenha: false,
    senha: '',
  },
};

export const dataSelector = state => state.ftp.data;

export const fetch = createAsyncThunk(
    'ftp/fetch',
    async (_, thunkAPI) => {
      const res = await api.get('/ftp');
      return res.data;
    },
);

export const update = createAsyncThunk(
    'ftp/update',
    async (payload, thunkAPI) => {
      try {
        await api.put('/ftp', payload);
        await thunkAPI.dispatch(fetch());
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const slice = createSlice({
  name: 'ftp',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: {
    [fetch.fulfilled]: (state, action) => {
      state.data = {...initialState.data, ...action.payload};
    },
  },
});

export const {clear} = slice.actions;

export default slice.reducer;
