import {Breadcrumb, Layout, Menu, message, Spin} from 'antd';
import React, {Suspense, useCallback, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import Brand from '../components/brand';
import Logo from '../components/logo';
import useDispatchWithResult from '../hooks/useDispatchWithResult';
import {logout} from '../reducers/auth';
import styles from './_styles.less';

export default function(props) {
  const {routes, children} = props;
  const auth = useSelector(state => state['auth']);
  const history = useHistory();
  const location = useLocation();
  const {pathname} = location;
  const dispatch = useDispatchWithResult();

  useEffect(() => {
    if (auth.userId === null)
      history.push('/entrar');
  }, [auth.userId])

  const handleLogoClick = useCallback(e => {
    e.preventDefault();
    history.push('/');
  }, [history]);

  const handleMenuSelect = useCallback(({key}) => {
    history.push(key);
  }, [history]);

  const handleSairClick = useCallback(() => {
    dispatch(logout())
        .then(() => {
          message.info('Você saiu.');
          history.push('/entrar');
        });
  }, [history]);

  const activeRoute = useMemo(() => {
    return routes.filter(route => pathname.startsWith(route.key))[0];
  }, [routes, pathname]);

  return (
      <Layout className={styles['layout']}>
        <Layout.Sider collapsed className={styles['sider']}>
          <div className={styles['logo']} onClick={handleLogoClick}>
            <Logo />
          </div>
          <Menu theme="dark" mode="inline" className={styles['menu']} defaultSelectedKeys={activeRoute?.path} onSelect={handleMenuSelect}>
            {routes.map(({key, icon, title}) => <Menu.Item key={key} icon={icon}>{title}</Menu.Item>)}
          </Menu>
        </Layout.Sider>
        <Layout>
          <Layout.Header theme="light" className={styles['header']}>
            <div className={styles['brand']}>
              <Brand />
            </div>
            <Menu mode="horizontal" className={styles['menu']}>
              <Menu.SubMenu title={auth.userEmail}>
                <Menu.Item key="1" onClick={handleSairClick}>Sair</Menu.Item>
              </Menu.SubMenu>
            </Menu>
          </Layout.Header>
          <Layout className={styles['content-wrapper']}>
            <Breadcrumb>
              <Breadcrumb.Item>{activeRoute?.drawerTitle}</Breadcrumb.Item>
            </Breadcrumb>
            <Layout.Content className={styles['content']}>
              <Suspense fallback={<Spin />}>
                {children}
              </Suspense>
            </Layout.Content>
          </Layout>
        </Layout>
      </Layout>
  );
};
