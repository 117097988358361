import {useEffect, useRef} from 'react';

export function useInterval(ms, handler) {
  const handlerRef = useRef();

  useEffect(() => {
    handlerRef.current = handler;
  });

  useEffect(() => {
    function tick() {
      handlerRef.current();
    }

    if (typeof ms === 'number') {
      const interval = setInterval(tick, ms);
      return () => clearInterval(interval);
    }
  }, [ms]);
}
