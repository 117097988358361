import {LoginOutlined, UserOutlined} from '@ant-design/icons';
import {Alert, Button, Card, Form, Input, Layout, message} from 'antd';
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import Brand from '../components/brand';
import Link from '../components/link';
import Logo from '../components/logo';
import useDispatchWithResult from '../hooks/useDispatchWithResult';
import SimpleLayout from '../layout/simple';
import {emailChanged, emailSelector, recoverPassword} from '../reducers/auth';
import {useLoading} from '../reducers/loading';
import {unwrapResult} from '../store';
import styles from './_styles.less';

const rules = {
  email: [{required: true, type: 'email'}],
};

const formLayout = {
  layout: 'horizontal',
  labelCol: {flex: '64px'},
  wrapperCol: {flex: 'auto'},
};

function Cover(props) {
  return (
      <Layout.Header className={styles['cover']}>
        <div><Logo /></div>
        <div><Brand dark /></div>
      </Layout.Header>
  );
}

const buttonStyle = {paddingLeft: '64px'};

export default function() {
  const [error, setError] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatchWithResult();
  const loading = useLoading(recoverPassword.typePrefix);
  const history = useHistory();
  const email = useSelector(emailSelector);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({email});
  }, [email]);

  const handleValuesChange = useCallback(changedValues => {
    const {email} = changedValues;
    if (email !== undefined)
      dispatch(emailChanged(email));
  }, []);

  const handleFinish = payload => {
    setError(false);
    dispatch(recoverPassword(payload), form)
        .then(unwrapResult)
        .then(() => {
          message.success('Verifique seu e-mail para cadastrar uma nova senha.');
          history.push('/entrar');
        })
        .catch(err => setError(err.message));
  };

  const alert = error ?
      <Alert showIcon message={error} type="error" className={styles['alert']} /> :
      <Alert showIcon message="Informe abaixo o e-mail cadastrado para iniciar o processo de recuperação de senha." type="info" className={styles['alert']} />;

  return (
      <SimpleLayout>
        <div className={styles['centered']}>
          <Card cover={<Cover />} className={styles['card']} bordered={false}>
            <Form
                form={form}
                onValuesChange={handleValuesChange}
                onFinish={handleFinish}
                {...formLayout} >
              {alert}
              <Form.Item name="email" label="E-mail" rules={rules.email}>
                <Input size="middle" prefix={<UserOutlined className={styles['prefix']} />} maxLength={100} autoFocus />
              </Form.Item>
              <Form.Item style={buttonStyle}>
                <Button size="middle" icon={<LoginOutlined />} type="primary" htmlType="submit" loading={loading}>Enviar e-mail</Button>
                <span className={styles['link']}>
                <Link to="/entrar" disabled={loading}>voltar para o login</Link>
              </span>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </SimpleLayout>
  );
};
