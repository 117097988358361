import {Checkbox, DatePicker, Form, Input, Tag} from 'antd';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link, useHistory, useParams} from 'react-router-dom';
import ActionBar from '../../common/components/action-bar';
import ActionButton from '../../common/components/action-button';
import MiniFormDrawer from '../../common/components/mini-form-drawer';
import MiniTable from '../../common/components/mini-table';
import Page from '../../common/components/page';
import useDispatchWithResult from '../../common/hooks/useDispatchWithResult';
import {useLoading} from '../../common/reducers/loading';
import {clear, clearOne, dataSourceSelector, fetch, fetchOne, insertOne, newOne, onRowSelectionChange, papeisOptionsSelector, removeSelectedRowKeys, search, selectedRowKeysSelector, updateOne, usuarioFormSelector} from './reducer';
import styles from './styles.less';

const rules = {
  email: [{required: true, type: 'email'}],
};

function Usuario(props) {
  const {id} = props;
  const history = useHistory();
  const [form] = Form.useForm();
  const usuario = useSelector(usuarioFormSelector);
  const papeis = useSelector(papeisOptionsSelector);
  const dispatch = useDispatchWithResult();
  const loading = useLoading(fetchOne.typePrefix);
  const loadingUpdate = useLoading(updateOne.typePrefix, insertOne.typePrefix);

  useEffect(() => {
    if (id === undefined)
      return;
    if (id === '+')
      dispatch(newOne());
    else
      dispatch(fetchOne(id));
  }, [id]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(usuario);
  }, [usuario]);

  const title = id === '+' ? 'Novo Usuário' : (id && usuario.email) ? usuario.email : <>&nbsp;</>;

  const footer = (
      <>
        <ActionButton.Save
            onClick={() => form.submit()}
            loading={loadingUpdate}
            disabled={loading}
        />
        <ActionButton.Close
            onClick={() => history.push('/usuarios')}
            disabled={loadingUpdate}
        />
      </>
  );

  const handleFinish = values => {
    if (id === '+') {
      return dispatch(insertOne(values), form)
          .then(payload => history.replace('/usuarios/' + payload.id));
    } else {
      return dispatch(updateOne({...values, id}), form)
          .then(() => history.replace('/usuarios/' + id));
    }
  };

  return (
      <MiniFormDrawer
          drawerTitle={title}
          drawerFooter={footer}
          drawerVisible={id !== undefined}
          drawerWidth={400}
          form={form}
          labelFlex='80px'
          loading={loading}
          onDrawerAfterClose={() => dispatch(clearOne())}
          onFinish={handleFinish}
      >
        <h4>Cadastro</h4>
        <Form.Item label="E-mail" name="email" rules={rules.email}>
          <Input maxLength={100} disabled={loadingUpdate} autoFocus />
        </Form.Item>
        <Form.Item name="ativo" valuePropName="checked" className={styles['tail-item']}>
          <Checkbox>Ativo</Checkbox>
        </Form.Item>
        {usuario.id &&
        <>
          <Form.Item name="dataSenha" label="Senha em">
            <DatePicker showTime placeholder="" format='DD/MM/YYYY HH:mm:ss' disabled />
          </Form.Item>
          <Form.Item name="dataCriacao" label="Criado em">
            <DatePicker showTime placeholder="" format='DD/MM/YYYY HH:mm:ss' disabled />
          </Form.Item>
        </>
        }
        <h4>Papéis</h4>
        <Form.Item name="papeis" className={styles['tail-item']}>
          <Checkbox.Group options={papeis} className={styles['checkbox-group']} />
        </Form.Item>
      </MiniFormDrawer>
  );
}

const columns = [
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email',
    width: 200,
    render: (text, record) => <Link to={`/usuarios/${record.id}`}>{text ?? '(não preenchido)'}</Link>,
  },
  {
    title: 'Papéis',
    key: 'papeis',
    render: (text, record) => record.papeisFormatados?.map(papel => <Tag>{papel}</Tag>),
  },
];

export default function() {
  const dispatch = useDispatchWithResult();
  const dataSource = useSelector(dataSourceSelector);
  const selectedRowKeys = useSelector(selectedRowKeysSelector);
  const loading = useLoading(fetch.typePrefix);
  const loadingRemoveSelectedRowKeys = useLoading(removeSelectedRowKeys.typePrefix);
  const params = useParams();

  useEffect(() => {
    dispatch(fetch());
    return () => dispatch(clear());
  }, []);

  return (
      <Page>
        <ActionBar onSearch={s => dispatch(search(s))} searchLoading={loading}>
          <ActionButton.New to="/usuarios/+" />
          <ActionButton.Remove
              disabled={selectedRowKeys.length === 0}
              onClick={() => dispatch(removeSelectedRowKeys())}
              loading={loadingRemoveSelectedRowKeys}
          />
        </ActionBar>
        <MiniTable
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            rowClassName={record => record.ativo ? undefined : 'inactive'}
            rowSelection={{
              selectedRowKeys,
              onChange: selectedRowKeys => dispatch(onRowSelectionChange(selectedRowKeys)),
            }}
            style={{flexGrow: 1}}
        />
        <Usuario id={params.id} />
      </Page>
  );
}
