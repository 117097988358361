import {CheckCircleFilled, CloseCircleFilled} from '@ant-design/icons';
import {Typography} from 'antd';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import ActionBar from '../../common/components/action-bar';
import ActionButton from '../../common/components/action-button';
import MiniTable from '../../common/components/mini-table';
import Page from '../../common/components/page';
import useDispatchWithResult from '../../common/hooks/useDispatchWithResult';
import {useInterval} from '../../common/hooks/useInterval';
import {useLoading} from '../../common/reducers/loading';
import {formatCNPJ, formatShortDateTime} from '../../common/utils/formatter';
import {clear, dataSourceSelector, fetch, onRowSelectionChange, removeSelectedRowKeys, search, selectedRowKeysSelector} from './reducer';

const columns = [
  {
    title: 'CNPJ',
    dataIndex: 'empresaCnpj',
    key: 'cnpj',
    width: 140,
    render: formatCNPJ,
  },
  {
    title: 'Empresa',
    dataIndex: 'empresaNome',
    key: 'empresa',
  },
  {
    title: 'Serviço',
    dataIndex: 'servicoNome',
    width: 160,
    key: 'servico',
  },
  {
    title: 'Versão',
    dataIndex: 'servicoVersao',
    width: 80,
    key: 'versao',
  },
  {
    title: 'IP Remoto',
    dataIndex: 'ipRemoto',
    width: 120,
    key: 'ipRemoto',
  },
  {
    title: 'Últ. Atu.',
    dataIndex: 'dataAtualizacao',
    key: 'dataAtualizacao',
    width: 120,
    align: 'right',
    render: (text, record) => {
      if (record.atrasado) {
        return (
            <Typography.Text type="danger" strong>
              <span style={{marginRight: '8px'}}>{formatShortDateTime(record.dataAtualizacao)}</span>
              <CloseCircleFilled />
            </Typography.Text>
        );
      } else {
        return (
            <Typography.Text type="success" strong>
              <span style={{marginRight: '8px'}}>{formatShortDateTime(record.dataAtualizacao)}</span>
              <CheckCircleFilled />
            </Typography.Text>
        );
      }
    },
  },
];

export default function() {
  const dispatch = useDispatchWithResult();
  const dataSource = useSelector(dataSourceSelector);
  const selectedRowKeys = useSelector(selectedRowKeysSelector);
  const loading = useLoading(fetch.typePrefix);
  const loadingRemoveSelectedRowKeys = useLoading(removeSelectedRowKeys.typePrefix);

  useEffect(() => {
    dispatch(fetch());
    return () => dispatch(clear());
  }, []);

  useInterval(60000, () => {
    dispatch(fetch());
  });

  return (
      <Page>
        <ActionBar onSearch={s => dispatch(search(s))} searchLoading={loading}>
          <ActionButton.Remove
              disabled={selectedRowKeys.length === 0}
              onClick={() => dispatch(removeSelectedRowKeys())}
              loading={loadingRemoveSelectedRowKeys}
          />
        </ActionBar>
        <MiniTable
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            rowClassName={record => record.atrasado ? 'error' : undefined}
            rowSelection={{
              selectedRowKeys,
              onChange: selectedRowKeys => dispatch(onRowSelectionChange(selectedRowKeys)),
            }}
            style={{flexGrow: 1}}
        />
      </Page>
  );
}
