import {Checkbox, Col, Form, Input, Row, Select} from 'antd';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link, useHistory, useParams} from 'react-router-dom';
import ActionBar from '../../common/components/action-bar';
import ActionButton from '../../common/components/action-button';
import MiniFormDrawer from '../../common/components/mini-form-drawer';
import MiniTable from '../../common/components/mini-table';
import MiniUpload from '../../common/components/mini-upload';
import Page from '../../common/components/page';
import useDispatchWithResult from '../../common/hooks/useDispatchWithResult';
import {useLoading} from '../../common/reducers/loading';
import {clear, clearOne, dataSourceSelector, fetch, fetchOne, insertOne, newOne, onRowSelectionChange, removeSelectedRowKeys, search, selectedRowKeysSelector, servicoFormSelector, updateOne} from './reducer';
import styles from './styles.less';

const gutter = [8, 0];

function Servico(props) {
  const {id} = props;
  const history = useHistory();
  const [form] = Form.useForm();
  const servico = useSelector(servicoFormSelector);
  const dispatch = useDispatchWithResult();
  const loading = useLoading(fetchOne.typePrefix);
  const loadingUpdate = useLoading(updateOne.typePrefix, insertOne.typePrefix);

  useEffect(() => {
    if (id === undefined)
      return;
    if (id === '+')
      dispatch(newOne());
    else
      dispatch(fetchOne(id));
  }, [id]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(servico);
  }, [servico]);

  const title = id === '+' ? 'Novo Serviço' : (id && servico.nome) ? servico.nome : <>&nbsp;</>;

  const footer = (
      <>
        <ActionButton.Save
            onClick={() => form.submit()}
            loading={loadingUpdate}
            disabled={loading}
        />
        <ActionButton.Close
            onClick={() => history.push('/servicos')}
            disabled={loadingUpdate}
        />
      </>
  );

  const handleFinish = values => {
    if (id === '+') {
      return dispatch(insertOne(values), form)
          .then(payload => history.replace('/servicos/' + payload.id));
    } else {
      return dispatch(updateOne({...values, id}), form)
          .then(() => history.replace('/servicos/' + id));
    }
  };

  return (
      <MiniFormDrawer
          drawerTitle={title}
          drawerFooter={footer}
          drawerVisible={id !== undefined}
          drawerWidth={500}
          form={form}
          labelFlex='80px'
          loading={loading}
          onDrawerAfterClose={() => dispatch(clearOne())}
          onFinish={handleFinish}
      >
        {(id === '+') && <>
          <h4>Arquivo (ZIP)</h4>
          <Form.Item label="Arquivo (ZIP)" name="arquivo" noStyle>
            <MiniUpload accept=".zip" />
          </Form.Item>
        </>}
        <h4>Cadastro</h4>
        <Form.Item label="Nome" name="nome">
          <Input maxLength={50} disabled={loadingUpdate} autoFocus />
        </Form.Item>
        <Row gutter={gutter}>
          <Col flex="1">
            <Form.Item label="Versão" name="versao">
              <Input maxLength={20} disabled={loadingUpdate || id !== '+'} />
            </Form.Item>
          </Col>
          <Col flex="1">
            <Form.Item name="publicado" valuePropName="checked" className={styles['tail-item']}>
              <Checkbox>Publicado</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={gutter}>
          <Col flex="1">
            <Form.Item label="Tipo" name="tipo">
              <Select>
                <Select.Option value="netcoreapp2.1">NetCoreApp 2.1</Select.Option>
                <Select.Option value="dotnet" disabled>DotNet</Select.Option>
                <Select.Option value="java">JRE 8</Select.Option>
                <Select.Option value="jre11">JRE 11</Select.Option>
                <Select.Option value="nginx">Nginx</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col flex="1">
            {(id !== '+') &&
            <Form.Item label="CRC32" name="crc32">
              <Input maxLength={8} disabled />
            </Form.Item>
            }
          </Col>
        </Row>
      </MiniFormDrawer>
  );
}

const columns = [
  {
    title: 'Nome',
    dataIndex: 'nome',
    key: 'nome',
    width: 160,
    render: (text, record, index) => <Link to={`/servicos/${record.id}`}>{text ?? '(não preenchido)'}</Link>,
  },
  {
    title: 'Versão',
    dataIndex: 'versao',
    width: 80,
    key: 'versao',
  },
  {
    title: 'Tipo',
    dataIndex: 'tipoNome',
    key: 'tipoNome',
  },
];

export default function() {
  const dispatch = useDispatchWithResult();
  const dataSource = useSelector(dataSourceSelector);
  const selectedRowKeys = useSelector(selectedRowKeysSelector);
  const loading = useLoading(fetch.typePrefix);
  const loadingRemoveSelectedRowKeys = useLoading(removeSelectedRowKeys.typePrefix);
  const params = useParams();

  useEffect(() => {
    dispatch(fetch());
    return () => dispatch(clear());
  }, []);

  return (
      <Page>
        <ActionBar onSearch={s => dispatch(search(s))} searchLoading={loading}>
          <ActionButton.New to="/servicos/+" />
          <ActionButton.Remove
              disabled={selectedRowKeys.length === 0}
              onClick={() => dispatch(removeSelectedRowKeys())}
              loading={loadingRemoveSelectedRowKeys}
          />
        </ActionBar>
        <MiniTable
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            rowClassName={record => record.publicado ? undefined : 'inactive'}
            rowSelection={{
              selectedRowKeys,
              onChange: selectedRowKeys => dispatch(onRowSelectionChange(selectedRowKeys)),
            }}
            style={{flexGrow: 1}}
        />
        <Servico id={params.id} />
      </Page>
  );
}
