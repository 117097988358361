import {Layout} from 'antd';
import React from 'react';
import styles from './_styles.less';

export default function SimpleLayout(props) {
  const {children} = props;
  return (
      <Layout className={styles['layout']}>
        {children}
      </Layout>
  );
}
