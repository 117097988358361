import PropTypes from 'prop-types';
import React from 'react';
import {NavLink} from 'react-router-dom';

function Link({to, disabled, children}) {
  if (disabled)
    return <span>{children}</span>;
  return <NavLink to={to}>{children}</NavLink>;
}

Link.defaultProps = {
  disabled: false,
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.any,
};

export default Link;
