// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3Is-C2EnI0e2P7VMSjMnt0 {\n  margin-left: 14px;\n}\n._3Is-C2EnI0e2P7VMSjMnt0 > * + * {\n  margin-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://src/common/components/mini-form-drawer/_styles.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAFA;EAII,gBAAA;AACJ","sourcesContent":[".footer {\n  margin-left: 14px;\n\n  > * + * {\n    margin-left: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "_3Is-C2EnI0e2P7VMSjMnt0"
};
export default ___CSS_LOADER_EXPORT___;
