import {Spin} from 'antd';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {versionSelector} from '../../reducers/app';
import styles from './_styles.less';

function Brand({dark}) {
  const version = useSelector(versionSelector);
  const loading = version === null;

  const className = useMemo(() => {
    if (dark)
      return styles['brand'] + ' ' + styles['dark'];
    return styles['brand'];
  }, [dark]);

  return (
      <div className={className}>
        <div className={styles['cloud']}>Cloud</div>
        <div className={styles['manager']}>Manager</div>
        <div className={styles['version']}>
          <Spin spinning={loading}>{version}</Spin>
        </div>
      </div>
  );
}

Brand.defaultProps = {
  dark: false,
};

Brand.propTypes = {
  dark: PropTypes.bool,
};

export default Brand;
