import {Table} from 'antd';
import React, {useLayoutEffect, useRef, useState} from 'react';
import styles from './_styles.less';

function MiniTable({style, rowSelection, ...props}) {
  const ref = useRef(null);
  const [pageSize, setPageSize] = useState(5);

  useLayoutEffect(() => {
    function resize() {
      setPageSize(Math.max(Math.floor((ref.current.clientHeight - 60) / 26.88), 5));
    }

    resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, [ref.current]);

  const showTotal = (total) => {
    let message = total === 1 ? `${total} item` : `${total} registros`;
    const selecionados = rowSelection?.selectedRowKeys?.length ?? 0;
    if (selecionados === 1)
      message = `${selecionados} selecionado de ${message}`;
    else if (selecionados > 1)
      message = `${selecionados} selecionados de ${message}`;
    return message;
  };

  return (
      <div ref={ref} className={styles['mini-table']} style={style}>
        <Table
            scroll={{x: true}}
            rowKey="id"
            pagination={{
              pageSize,
              showTotal,
              showSizeChanger: false,
            }}
            rowSelection={rowSelection}
            {...props}
        />
      </div>
  );
}

export default MiniTable;
