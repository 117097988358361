// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._366ZaVSW3R9NZ6jyktS1od {\n  float: right;\n  max-width: 400px;\n}\n", "",{"version":3,"sources":["webpack://src/common/components/search/_styles.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;AACF","sourcesContent":[".search {\n  float: right;\n  max-width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": "_366ZaVSW3R9NZ6jyktS1od"
};
export default ___CSS_LOADER_EXPORT___;
