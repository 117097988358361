// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2_cm12qxV22CS3It0lwuJC > * {\n  display: block;\n}\n._2mW3ISl8BMSQ9gNnXnGyHv {\n  padding-left: 128px;\n  margin-left: 14px;\n}\n._2rgDqGBmOl0Ltx43xeItGr {\n  min-width: 90px;\n  text-align: right;\n  font-weight: bold;\n}\n._2rgDqGBmOl0Ltx43xeItGr > small {\n  margin-left: 4px;\n}\n._1im57Or44LZLNds_USwPXv {\n  padding-left: 80px;\n}\n", "",{"version":3,"sources":["webpack://src/features/ferramentas/styles.less"],"names":[],"mappings":"AAAA;EAEI,cAAA;AAAJ;AAIA;EACE,mBAAA;EACA,iBAAA;AAFF;AAKA;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;AAHF;AAAA;EAMI,gBAAA;AAHJ;AAOA;EACE,kBAAA;AALF","sourcesContent":[".checkbox-group {\n  > * {\n    display: block;\n  }\n}\n\n.footer {\n  padding-left: 128px;\n  margin-left: 14px;\n}\n\n.artefato {\n  min-width: 90px;\n  text-align: right;\n  font-weight: bold;\n\n  > small {\n    margin-left: 4px;\n  }\n}\n\n.tail-item {\n  padding-left: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox-group": "_2_cm12qxV22CS3It0lwuJC",
	"footer": "_2mW3ISl8BMSQ9gNnXnGyHv",
	"artefato": "_2rgDqGBmOl0Ltx43xeItGr",
	"tail-item": "_1im57Or44LZLNds_USwPXv"
};
export default ___CSS_LOADER_EXPORT___;
