import {LockOutlined, LoginOutlined, UserOutlined} from '@ant-design/icons';
import {Alert, Button, Card, Form, Input, Layout, message} from 'antd';
import jwt_decode from 'jwt-decode';
import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import Brand from '../components/brand';
import Link from '../components/link';
import Logo from '../components/logo';
import useDispatchWithResult from '../hooks/useDispatchWithResult';
import useQuery from '../hooks/useQuery';
import SimpleLayout from '../layout/simple';
import {createPassword, emailChanged} from '../reducers/auth';
import {useLoading} from '../reducers/loading';
import {unwrapResult} from '../store';
import styles from './_styles.less';

const initialValues = {
  senha: '',
  senha2: '',
};

const rules = {
  senha: [{required: true}],
  senha2: [
    {required: true}, form => ({
      async validator(rule, value) {
        if (!value || form.getFieldValue('senha') === value)
          return;
        throw new Error('As senhas são diferentes');
      },
    })],
};

const formLayout = {
  layout: 'horizontal',
  labelCol: {flex: '144px'},
  wrapperCol: {flex: 'auto'},
};

function Cover(props) {
  return (
      <Layout.Header className={styles['cover']}>
        <div><Logo /></div>
        <div><Brand dark /></div>
      </Layout.Header>
  );
}

const buttonStyle = {paddingLeft: '144px'};

export default function() {
  const [error, setError] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatchWithResult();
  const loading = useLoading(createPassword.typePrefix);
  const query = useQuery();
  const history = useHistory();

  const token = query.get('token');
  const jwt = jwt_decode(token);
  const now = parseInt(new Date().getTime() / 1000);
  if (now < jwt.iat || now > jwt.exp) {
    message.error('Token expirado.');
    history.replace('/entrar');
    return null;
  }

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({senha: '', senha2: ''});
  }, []);

  useEffect(() => {
    dispatch(emailChanged(jwt.email));
  }, []);

  const handleFinish = useCallback(payload => {
    setError(false);
    const {senha} = payload;
    dispatch(createPassword({token, senha}), form)
        .then(unwrapResult)
        .then(() => {
          message.success('Senha cadastrada com sucesso.');
          history.replace('/entrar');
        })
        .catch(err => setError(err.message));
  }, []);

  const alert = error ?
      <Alert showIcon message={error} type="error" className={styles['alert']} /> :
      <Alert showIcon message="Cadastrar uma nova senha." type="info" className={styles['alert']} />;

  return (
      <SimpleLayout>
        <div className={styles['centered']}>
          <Card cover={<Cover />} className={styles['nova-senha-card']} bordered={false}>
            <Form
                form={form}
                onValuesChange={handleValuesChange}
                onFinish={handleFinish}
                {...formLayout} >
              {alert}
              <Form.Item label="E-mail">
                <Input size="middle" prefix={<UserOutlined className={styles['prefix']} />} maxLength={100} disabled={loading} readOnly value={jwt.email} />
              </Form.Item>
              <Form.Item name="senha" label="Nova Senha" rules={rules.senha}>
                <Input.Password size="middle" prefix={<LockOutlined className={styles['prefix']} />} maxLength={32} disabled={loading} autoFocus />
              </Form.Item>
              <Form.Item name="senha2" label="Confirme a Senha" rules={rules.senha2}>
                <Input.Password size="middle" prefix={<LockOutlined className={styles['prefix']} />} maxLength={32} disabled={loading} />
              </Form.Item>
              <Form.Item style={buttonStyle}>
                <Button size="middle" icon={<LoginOutlined />} type="primary" htmlType="submit" loading={loading}>Cadastrar</Button>
                <span className={styles['link']}>
                <Link to="/entrar" disabled={loading}>voltar para o login</Link>
              </span>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </SimpleLayout>
  );
};
