import {createAsyncThunk, createSelector, createSlice} from '@reduxjs/toolkit';
import api from '../../common/api';
import {formatCNPJ} from '../../common/utils/formatter';
import {parseMoment} from '../../common/utils/parser';
import {compare, contains} from '../../common/utils/text';

const initialState = {
  byId: {},
  search: '',
  selectedRowKeys: [],
  empresa: false,
  instaladores: {
    byId: {},
  },
  servicos: {
    byId: {},
  },
};

export const byIdSelector = state => state.empresas.byId;
export const searchSelector = state => state.empresas.search;
export const selectedRowKeysSelector = state => state.empresas.selectedRowKeys;
export const empresaSelector = state => state.empresas.empresa;
export const instaladoresSelector = state => state.empresas.instaladores;
export const servicosSelector = state => state.empresas.servicos;

export const dataSourceSelector = createSelector(
    byIdSelector,
    searchSelector,
    (byId, search) => {
      const words = search.split(/\s+/).map(value => value.trim()).filter(value => value.length > 0);
      let values = Object.values(byId);
      if (words.length > 0)
        values = values.filter(row => {
          for (const word of words) {
            if (contains(row.cnpj, word)) continue;
            if (contains(row.cnpjFormatado, word)) continue;
            if (contains(row.nome, word)) continue;
            return false;
          }
          return true;
        });
      return values.sort((a, b) => compare(a.cnpj, b.cnpj));
    },
);

export const empresaFormSelector = createSelector(
    empresaSelector,
    empresa =>
        empresa ? ({
          ...empresa,
          dataCriacao: parseMoment(empresa.dataCriacao),
        }) : {},
);

export const fetchInstaladores = createAsyncThunk(
    'empresas/fetchInstaladores',
    async (_, thunkAPI) => {
      const res = await api.get('/empresas/instaladores');
      return res.data;
    },
);

export const fetchServicos = createAsyncThunk(
    'empresas/fetchServicos',
    async (_, thunkAPI) => {
      const res = await api.get('/empresas/servicos');
      return res.data;
    },
);

export const fetch = createAsyncThunk(
    'empresas/fetch',
    async (_, thunkAPI) => {
      const res = await api.get('/empresas');
      return res.data;
    },
);

export const removeSelectedRowKeys = createAsyncThunk(
    'empresas/removeSelectedRowKeys',
    async (_, thunkAPI) => {
      try {
        const {selectedRowKeys} = thunkAPI.getState().empresas;
        await Promise.all(selectedRowKeys
            .map(id => api.delete(`/empresas/${id}`)));
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const fetchOne = createAsyncThunk(
    'empresas/fetchOne',
    async (id, thunkAPI) => {
      const res = await api.get(`/empresas/${id}`);
      return res.data;
    },
);

export const updateOne = createAsyncThunk(
    'empresas/updateOne',
    async (payload, thunkAPI) => {
      try {
        const {id, ...empresa} = payload;
        await api.put(`/empresas/${id}`, empresa);
        await thunkAPI.dispatch(fetchOne(id));
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const insertOne = createAsyncThunk(
    'empresas/insertOne',
    async (payload, thunkAPI) => {
      try {
        const res = await api.post(`/empresas`, payload);
        await thunkAPI.dispatch(fetchOne(res.data.id));
        return res.data;
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const slice = createSlice({
  name: 'empresas',
  initialState,
  reducers: {
    clear: () => initialState,
    search: (state, action) => {
      state.search = action.payload;
    },
    onRowSelectionChange: (state, action) => {
      state.selectedRowKeys = action.payload;
    },
    clearOne: (state) => {
      state.empresa = false;
    },
    newOne: (state) => {
      state.empresa = {
        cnpj: '',
        ativo: true,
        nome: '',
        chave: '',
        logo: '',
        servidorHost: '',
        servidorPorta: 7080,
        servidorSsl: false,
        licencaProcyonUsuarios: 0,
        licencaProcyonServicesUsuarios: 0,
        licencaProcyonManagerUsuarios: 0,
        licencaSmartSpedCnpjs: 0,
        instaladores: ['ccloud'],
        servicos: [],
      };
    },
  },
  extraReducers: {
    [fetch.fulfilled]: (state, action) => {
      state.byId = action.payload.reduce((pv, cv) => {
        pv[cv.id] = {
          ...cv,
          cnpjFormatado: formatCNPJ(cv.cnpj),
        };
        return pv;
      }, {});
    },
    [removeSelectedRowKeys.fulfilled]: (state, action) => {
      state.selectedRowKeys.forEach(id => delete state.byId[id]);
      state.selectedRowKeys = [];
    },
    [fetchOne.fulfilled]: (state, action) => {
      const {id, cnpj, nome, ativo} = action.payload;
      state.empresa = {...initialState.data, ...action.payload};
      state.byId[action.payload.id] = {id, cnpj, nome, ativo};
    },
    [fetchInstaladores.fulfilled]: (state, action) => {
      state.instaladores = action.payload;
    },
    [fetchServicos.fulfilled]: (state, action) => {
      state.servicos = action.payload;
    },
  },
});

export const {clear, search, onRowSelectionChange, clearOne, newOne} = slice.actions;

export default slice.reducer;
