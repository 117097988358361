import {Checkbox, Col, DatePicker, Form, Input, Row, Tag} from 'antd';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link, useHistory, useParams} from 'react-router-dom';
import ActionBar from '../../../common/components/action-bar';
import ActionButton from '../../../common/components/action-button';
import MiniFormDrawer from '../../../common/components/mini-form-drawer';
import MiniTable from '../../../common/components/mini-table';
import MiniUpload from '../../../common/components/mini-upload';
import useDispatchWithResult from '../../../common/hooks/useDispatchWithResult';
import {useLoading} from '../../../common/reducers/loading';
import {artefatoFormSelector, clear, clearOne, dataSourceSelector, fetch, fetchOne, insertOne, newOne, onRowSelectionChange, removeSelectedRowKeys, selectedRowKeysSelector, updateOne} from './reducer';
import styles from './styles.less';

const gutter = [8, 0];

function ArtefatoTag({instaladorId, id, versao, arquitetura, ativo}) {
  const color = ativo ? arquitetura === 'x86' ? 'purple' : 'geekblue' : 'gray';
  return (
      <Link to={`/instaladores/${instaladorId}/artefatos/${id}`}>
        <Tag color={color} className={styles['artefato']}>{versao}<small>{arquitetura}</small></Tag>
      </Link>
  );
}

function Artefato({instaladorId, id}) {
  const history = useHistory();
  const [form] = Form.useForm();
  const artefato = useSelector(artefatoFormSelector);
  const dispatch = useDispatchWithResult();
  const loading = useLoading(fetchOne.typePrefix);
  const loadingUpdate = useLoading(updateOne.typePrefix, insertOne.typePrefix);

  useEffect(() => {
    if (id === undefined)
      return;
    if (id === '+')
      dispatch(newOne({instaladorId}));
    else
      dispatch(fetchOne({instaladorId, id}));
  }, [id]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(artefato);
  }, [artefato]);

  const title = id === '+' ? 'Novo Artefato' : (id && artefato.versao) ? artefato.versao + ' - ' + artefato.arquitetura : <>&nbsp;</>;

  const footer = (
      <>
        <ActionButton.Save
            onClick={() => form.submit()}
            loading={loadingUpdate}
            disabled={loading}
        />
        <ActionButton.Close
            onClick={() => history.push(`/instaladores/${instaladorId}/artefatos`)}
            disabled={loadingUpdate}
        />
      </>
  );

  const handleFinish = values => {
    if (id === '+') {
      return dispatch(insertOne(values), form)
          .then(payload => history.replace(`/instaladores/${instaladorId}/artefatos/${payload.id}`));
    } else {
      return dispatch(updateOne({...values, id}), form)
          .then(() => history.replace(`/instaladores/${instaladorId}/artefatos/${id}`));
    }
  };

  return (
      <MiniFormDrawer
          drawerTitle={title}
          drawerFooter={footer}
          drawerVisible={id !== undefined}
          drawerWidth={500}
          form={form}
          labelFlex='96px'
          loading={loading}
          onDrawerAfterClose={() => dispatch(clearOne())}
          onFinish={handleFinish}
      >
        {(id === '+') && <>
          <h4>Arquivo (ZIP)</h4>
          <Form.Item label="Arquivo (ZIP)" name="arquivo" noStyle>
            <MiniUpload accept=".zip" />
          </Form.Item>
        </>}
        <h4>Cadastro</h4>
        <Row gutter={gutter}>
          <Col flex="1">
            <Form.Item label="Versão" name="versao">
              <Input maxLength={20} disabled={loadingUpdate || id !== '+'} />
            </Form.Item>
          </Col>
          <Col flex="1">
            {(id !== '+') &&
            <Form.Item label="CRC32" name="crc32">
              <Input maxLength={8} disabled />
            </Form.Item>
            }
          </Col>
        </Row>
        <Row gutter={gutter}>
          <Col flex="1">
            <Form.Item label="Arquiteturas" name="arquiteturas">
              <Checkbox.Group disabled={loadingUpdate || id !== '+'}>
                <Checkbox value="x64">x64</Checkbox>
                <Checkbox value="x86">x86</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
          <Col flex="1">
            <Form.Item name="ativo" valuePropName="checked" className={styles['tail-item']}>
              <Checkbox>Ativo</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        {(id !== '+') &&
        <Form.Item label="Criado em" name="dataCriacao">
          <DatePicker showTime placeholder="" format='DD/MM/YYYY HH:mm:ss' disabled />
        </Form.Item>
        }
      </MiniFormDrawer>
  );
}

export default function({instaladorId}) {
  const dispatch = useDispatchWithResult();
  const dataSource = useSelector(dataSourceSelector);
  const selectedRowKeys = useSelector(selectedRowKeysSelector);
  const loading = useLoading(fetch.typePrefix);
  const loadingRemoveSelectedRowKeys = useLoading(removeSelectedRowKeys.typePrefix);
  const params = useParams();

  useEffect(() => {
    dispatch(fetch(instaladorId));
    return () => dispatch(clear());
  }, [instaladorId]);

  const columns = [
    {
      title: 'Versão',
      dataIndex: 'versao',
      key: 'versao',
      width: 160,
    },
    {
      title: 'Arquitetura',
      key: 'artefatos.arquitetura',
      render: (text, record, index) => record.artefatos.map(artefato => <ArtefatoTag key={artefato.id} instaladorId={instaladorId} {...artefato} />),
    },
  ];

  return (
      <>
        <ActionBar>
          <ActionButton.New to={`/instaladores/${instaladorId}/artefatos/+`} />
          <ActionButton.Remove
              disabled={selectedRowKeys.length === 0}
              onClick={() => dispatch(removeSelectedRowKeys())}
              loading={loadingRemoveSelectedRowKeys}
          />
        </ActionBar>
        <MiniTable
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            rowKey="versao"
            rowClassName={record => record.ativo ? undefined : 'inactive'}
            rowSelection={{
              selectedRowKeys,
              onChange: selectedRowKeys => dispatch(onRowSelectionChange(selectedRowKeys)),
            }}
            style={{flexGrow: 1}}
        />
        <Artefato instaladorId={instaladorId} id={params.artefatoId} />
      </>
  );
}
