import {Checkbox, Form, Input} from 'antd';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import ActionButton from '../../common/components/action-button';
import MiniForm from '../../common/components/mini-form';
import useDispatchWithResult from '../../common/hooks/useDispatchWithResult';
import {useLoading} from '../../common/reducers/loading';
import {clear, dataSelector, fetch, update} from './reducer';

const buttonStyle = {paddingLeft: '64px', margin: '0'};

const urlStyle = {maxWidth: '400px'};
const usuarioStyle = {maxWidth: '200px'};
const senhaStyle = {maxWidth: '200px'};

export default function() {
  const [form] = Form.useForm();
  const data = useSelector(dataSelector);
  const dispatch = useDispatchWithResult();
  const loading = useLoading(fetch.typePrefix);
  const loadingUpdate = useLoading(update.typePrefix);

  useEffect(() => {
    dispatch(fetch());
    return () => dispatch(clear());
  }, []);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(data);
  }, [data]);

  return (
      <MiniForm
          form={form}
          labelFlex='64px'
          loading={loading}
          onFinish={values => dispatch(update(values))}
      >
        <Form.Item label="URL" name="url">
          <Input maxLength={100} style={urlStyle} disabled={loadingUpdate} />
        </Form.Item>
        <Form.Item label="Usuário" name="usuario">
          <Input maxLength={50} style={usuarioStyle} disabled={loadingUpdate} />
        </Form.Item>
        <Form.Item name="alterarSenha" valuePropName="checked" style={buttonStyle}>
          <Checkbox disabled={loadingUpdate}>Alterar Senha</Checkbox>
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(pv, cv) => pv.alterarSenha !== cv.alterarSenha}>
          {() =>
              <Form.Item label="Senha" name="senha">
                <Input.Password maxLength={50} style={senhaStyle} disabled={loadingUpdate || !form.getFieldValue('alterarSenha')} />
              </Form.Item>
          }
        </Form.Item>
        <Form.Item style={buttonStyle}>
          <ActionButton.Save htmlType="submit" loading={loadingUpdate} />
        </Form.Item>
      </MiniForm>
  );
}
