import {FileZipTwoTone} from '@ant-design/icons';
import {Upload} from 'antd';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import styles from './_styles.less';

function MiniUpload({accept, name, onChange, value}) {
  const selfControlled = useMemo(() => value === undefined, [value]);

  const [files, setFiles] = useState([]);

  const handleBeforeUpload = file => {
    if (typeof onChange === 'function')
      onChange(file);
    if (selfControlled)
      setFiles([file]);
    return false;
  };

  const handleRemove = file => {
    if (selfControlled)
      setFiles([]);
    if (typeof onChange === 'function')
      onChange(null);
  };

  const fileList = useMemo(() => {
    if (selfControlled)
      return files;
    return value ? [value] : [];
  }, [selfControlled, value, files]);

  return (
      <div className={styles['dragger']}>
        <Upload.Dragger
            accept={accept}
            name={name}
            multiple={false}
            fileList={fileList}
            beforeUpload={handleBeforeUpload}
            onRemove={handleRemove}
        >
          <p className={styles['icon']}>
            <FileZipTwoTone />
          </p>
          <p>Arraste aqui</p>
        </Upload.Dragger>
      </div>
  );
}

MiniUpload.defaultProps = {
  name: 'arquivo',
};

MiniUpload.propTypes = {
  accept: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.objectOf(File),
};

export default MiniUpload;
