// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3zHlOALQdDurToojoylG4x > * {\n  display: block;\n}\n._3KfcCe_Nkn5fyfpILDXg7V {\n  padding-left: 128px;\n  margin-left: 14px;\n}\n.N6jNxZrWr4Q5OnaZgKX1X {\n  min-width: 90px;\n  text-align: right;\n  font-weight: bold;\n}\n.N6jNxZrWr4Q5OnaZgKX1X > small {\n  margin-left: 4px;\n}\n._2Cojk3Sg9_rUSzYPJmJGc0 {\n  padding-left: 80px;\n}\n", "",{"version":3,"sources":["webpack://src/features/servicos/styles.less"],"names":[],"mappings":"AAAA;EAEI,cAAA;AAAJ;AAIA;EACE,mBAAA;EACA,iBAAA;AAFF;AAKA;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;AAHF;AAAA;EAMI,gBAAA;AAHJ;AAOA;EACE,kBAAA;AALF","sourcesContent":[".checkbox-group {\n  > * {\n    display: block;\n  }\n}\n\n.footer {\n  padding-left: 128px;\n  margin-left: 14px;\n}\n\n.artefato {\n  min-width: 90px;\n  text-align: right;\n  font-weight: bold;\n\n  > small {\n    margin-left: 4px;\n  }\n}\n\n.tail-item {\n  padding-left: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox-group": "_3zHlOALQdDurToojoylG4x",
	"footer": "_3KfcCe_Nkn5fyfpILDXg7V",
	"artefato": "N6jNxZrWr4Q5OnaZgKX1X",
	"tail-item": "_2Cojk3Sg9_rUSzYPJmJGc0"
};
export default ___CSS_LOADER_EXPORT___;
