import {Drawer} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import MiniForm from '../mini-form';
import styles from './_styles.less';

function MiniFormDrawer({children, drawerFooter, drawerTitle, drawerVisible, drawerWidth, form, labelFlex, loading, onDrawerClose, onDrawerAfterClose, onFinish}) {
  const handleAfterVisibleChange = visible => {
    if (!visible && typeof onDrawerAfterClose === 'function')
      onDrawerAfterClose();
  };

  const footer = drawerFooter ? (
      <div className={styles['footer']} style={{paddingLeft: labelFlex}}>
        {drawerFooter}
      </div>) : undefined;

  return (
      <Drawer
          closable={false}
          mask={false}
          width={drawerWidth}
          title={drawerTitle}
          visible={drawerVisible}
          push={{distance: drawerWidth}}
          onClose={onDrawerClose}
          afterVisibleChange={handleAfterVisibleChange}
          footer={footer}
      >
        <MiniForm
            children={children}
            form={form}
            labelFlex={labelFlex}
            loading={loading}
            onFinish={onFinish}
        />
      </Drawer>
  );
}

MiniFormDrawer.propTypes = {
  children: PropTypes.node,
  drawerFooter: PropTypes.node,
  drawerTitle: PropTypes.node,
  drawerVisible: PropTypes.bool,
  drawerWidth: PropTypes.number,
  form: PropTypes.object,
  labelFlex: PropTypes.string,
  loading: PropTypes.bool,
  onDrawerClose: PropTypes.bool,
  onDrawerAfterClose: PropTypes.func,
  onFinish: PropTypes.func,
};

export default MiniFormDrawer;
