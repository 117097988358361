import unorm from 'unorm';

const collator = new Intl.Collator('pt-BR', {usage: 'search', sensitivity: 'base'});

export function compare(a, b) {
  if (a === b)
    return 0;
  if (a === undefined || a === null)
    return 1;
  if (b === undefined || b === null)
    return -1;
  return collator.compare(a, b);
}

function match(char) {
  const normalizedChar = unorm.nfkd(char).replace(/[\u0300-\u036f]/gi, '');
  const comparison = compare(char, normalizedChar);
  if (comparison === 0)
    return normalizedChar;
  return char;
}

export function normalize(str) {
  if (str === undefined || str === null)
    return '';
  return str.replace(/[^\u0000-\u007E]/g, match);
}

export function contains(a, b) {
  if (a === b)
    return true;
  return normalize(a).toLowerCase().includes(normalize(b).toLowerCase());
}
