import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from '../api';
import {fetchUser} from './auth';

export const versionSelector = state => state.app.version;

export const fetch = createAsyncThunk(
    'app/fetch',
    async (_, thunkAPI) => {
      while (true)
        try {
          const res = await api.get('/', {baseURL: '/manager/api'});
          return res.data;
        } catch (e) {
          await new Promise(resolve => setTimeout(resolve, 10000));
          if (thunkAPI.signal.aborted) throw e;
        }
    },
);

export const init = createAsyncThunk(
    'app/init',
    async (_, thunkAPI) => {
      await thunkAPI.dispatch(fetch());
      const resultAction = await thunkAPI.dispatch(fetchUser());
      if (fetchUser.rejected.match(resultAction))
        throw resultAction.error;
    },
);

export const slice = createSlice({
  name: 'app',
  initialState: {
    version: null,
  },
  reducers: {},
  extraReducers: {
    [fetch.fulfilled]: (state, action) => {
      state.version = action.payload.versao;
    },
  },
});

export default slice.reducer;
