import {Col, Row} from 'antd';
import PropTypes from 'prop-types';
import React, {Children} from 'react';
import Search from '../search';

function ActionBar({children, onSearch, searchLoading}) {
  return (
      <Row gutter={[8, 8]}>
        {Children.toArray(children).map((node, index) => <Col key={index}>{node}</Col>)}
        {onSearch &&
        <Col flex="auto">
          <Search onSearch={onSearch} loading={searchLoading} />
        </Col>
        }
      </Row>
  );
}

ActionBar.propTypes = {
  children: PropTypes.node,
  onSearch: PropTypes.func,
  searchLoading: PropTypes.bool,
};

export default ActionBar;
