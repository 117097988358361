import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api, {setAccessToken} from '../api';

const initialState = {
  userId: null,
  userEmail: null,
  userRoles: [],

  email: localStorage.getItem('email') ?? '',
  lembrar: !!localStorage.getItem('email'),
};

export const userRolesSelector = state => state.auth.userRoles;
export const emailSelector = state => state.auth.email;
export const lembrarSelector = state => state.auth.lembrar;

export const fetchUser = createAsyncThunk(
    'auth/fetchUser',
    async (_, thunkAPI) => {
      const res = await api.get('/usuario');
      return res.data;
    },
);

export const login = createAsyncThunk(
    'auth/login',
    async ({email, senha, lembrar}, thunkAPI) => {
      try {
        const res = await api.post('/token', {email, senha});
        setAccessToken(res.data.token);
        await thunkAPI.dispatch(fetchUser());
        return {lembrar, email};
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const logout = createAsyncThunk(
    'auth/logout',
    (action, thunkAPI) => {
      setAccessToken(false);
    },
);

export const recoverPassword = createAsyncThunk(
    'auth/recoverPassword',
    async (payload, thunkAPI) => {
      try {
        const url = location.protocol + '//' + location.host + location.pathname.replace(/recuperar-senha$/, 'nova-senha');
        await api.post('/envia-token-senha', payload, {params: {url}});
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const createPassword = createAsyncThunk(
    'auth/createPassword',
    async (payload, thunkAPI) => {
      try {
        const {token, ...campos} = payload;
        await api.post('/altera-senha', campos, {params: {token}});
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    emailChanged: (state, action) => {
      state.email = action.payload;
    },
  },
  extraReducers: {
    [fetchUser.fulfilled]: (state, action) => {
      state.userId = action.payload.id;
      state.userEmail = action.payload.email;
      state.userRoles = action.payload.papeis;
    },
    [fetchUser.rejected]: (state, action) => {
      state.userId = null;
      state.userEmail = null;
      state.userRoles = [];
    },
    [login.fulfilled]: (state, action) => {
      state.email = action.payload.email;
      state.lembrar = action.payload.lembrar;
      if (action.payload.lembrar)
        localStorage.setItem('email', action.payload.email);
      else
        localStorage.removeItem('email');
    },
    [logout.pending]: (state, action) => {
      state.userId = null;
      state.userEmail = null;
      state.userRoles = [];
    },
  },
});

export const {emailChanged} = slice.actions;

export default slice.reducer;
