import {configureStore} from '@reduxjs/toolkit';
import empresas from '../../features/empresas/reducer';
import ferramentasArtefatos from '../../features/ferramentas/artefatos/reducer';
import ferramentas from '../../features/ferramentas/reducer';
import ftp from '../../features/ftp/reducer';
import instaladoresArtefatos from '../../features/instaladores/artefatos/reducer';
import instaladores from '../../features/instaladores/reducer';
import monitorServicos from '../../features/monitor-servicos/reducer';
import servicos from '../../features/servicos/reducer';
import usuarios from '../../features/usuarios/reducer';
import app, {init} from '../reducers/app';
import auth from '../reducers/auth';
import errors from '../reducers/errors';
import loading from '../reducers/loading';

const store = configureStore({
  reducer: {
    app,
    loading,
    errors,
    auth,
    ftp,
    empresas,
    monitorServicos,
    ferramentas,
    ferramentasArtefatos,
    instaladores,
    instaladoresArtefatos,
    servicos,
    usuarios,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

store.dispatch(init());

export default store;

export function unwrapResult(actionResult) {
  if (actionResult?.error) {
    if (actionResult?.payload)
      return Promise.reject(actionResult.payload);
    return Promise.reject(actionResult.error);
  }
  return Promise.resolve(actionResult?.payload);
}
