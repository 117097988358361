import {createAsyncThunk, createSelector, createSlice} from '@reduxjs/toolkit';
import moment from 'moment';
import api from '../../common/api';
import {formatCNPJ, formatShortDateTime} from '../../common/utils/formatter';
import {parseMoment} from '../../common/utils/parser';
import {compare, contains} from '../../common/utils/text';

const initialState = {
  byId: {},
  search: '',
  selectedRowKeys: [],
};

export const byIdSelector = state => state.monitorServicos.byId;
export const searchSelector = state => state.monitorServicos.search;
export const selectedRowKeysSelector = state => state.monitorServicos.selectedRowKeys;

export const dataSourceSelector = createSelector(
    byIdSelector,
    searchSelector,
    (byId, search) => {
      const words = search.split(/\s+/).map(value => value.trim()).filter(value => value.length > 0);
      let values = Object.values(byId);
      if (words.length > 0)
        values = values.filter(row => {
          for (const word of words) {
            if (contains(row.empresaCnpj, word)) continue;
            if (contains(row.empresaCnpjFormatado, word)) continue;
            if (contains(row.empresaNome, word)) continue;
            if (contains(row.servicoNome, word)) continue;
            if (contains(row.servicoVersao, word)) continue;
            if (contains(row.ipRemoto, word)) continue;
            if (contains(row.dataAtualizacaoFormatado, word)) continue;
            return false;
          }
          return true;
        });
      return values.sort((a, b) => compare(a.dataAtualizacao, b.dataAtualizacao));
    },
);

export const fetch = createAsyncThunk(
    'monitorServicos/fetch',
    async (_, thunkAPI) => {
      const res = await api.get('/monitor-servicos');
      return res.data;
    },
);

export const removeSelectedRowKeys = createAsyncThunk(
    'monitorServicos/removeSelectedRowKeys',
    async (_, thunkAPI) => {
      try {
        const {byId, selectedRowKeys} = thunkAPI.getState().monitorServicos;
        await Promise.all(selectedRowKeys
            .map(id => byId[id])
            .map(({empresaCnpj, servicoNome}) => api.delete(`/monitor-servicos`, {params: {empresaCnpj, servicoNome}})));
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const slice = createSlice({
  name: 'monitorServicos',
  initialState,
  reducers: {
    clear: () => initialState,
    search: (state, action) => {
      state.search = action.payload;
    },
    onRowSelectionChange: (state, action) => {
      state.selectedRowKeys = action.payload;
    },
  },
  extraReducers: {
    [fetch.fulfilled]: (state, action) => {
      const now = moment();
      const isAtrasado = dataAtualizacao => {
        const data = parseMoment(dataAtualizacao);
        if (data == null)
          return true;
        const diff = now.diff(data, 'm');
        return diff >= 90;
      }
      state.byId = action.payload.reduce((pv, cv) => {
        pv[cv.id] = {
          ...cv,
          empresaCnpjFormatado: formatCNPJ(cv.empresaCnpj),
          dataAtualizacaoFormatado: formatShortDateTime(cv.dataAtualizacao),
          atrasado: isAtrasado(cv.dataAtualizacao)
        };
        return pv;
      }, {});
    },
    [removeSelectedRowKeys.fulfilled]: (state, action) => {
      state.selectedRowKeys.forEach(id => delete state.byId[id]);
      state.selectedRowKeys = [];
    },
  },
});

export const {clear, search, onRowSelectionChange} = slice.actions;

export default slice.reducer;
