import {Checkbox, DatePicker, Form, Input, Tag} from 'antd';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link, useHistory, useParams} from 'react-router-dom';
import ActionBar from '../../common/components/action-bar';
import ActionButton from '../../common/components/action-button';
import MiniFormDrawer from '../../common/components/mini-form-drawer';
import MiniTable from '../../common/components/mini-table';
import Page from '../../common/components/page';
import useDispatchWithResult from '../../common/hooks/useDispatchWithResult';
import {useLoading} from '../../common/reducers/loading';
import Artefatos from './artefatos/page';
import {clear, clearOne, dataSourceSelector, ferramentaFormSelector, fetch, fetchOne, insertOne, newOne, onRowSelectionChange, removeSelectedRowKeys, search, selectedRowKeysSelector, updateOne} from './reducer';
import styles from './styles.less';

const gutter = [8, 0];

function Ferramenta(props) {
  const {id} = props;
  const history = useHistory();
  const [form] = Form.useForm();
  const ferramenta = useSelector(ferramentaFormSelector);
  const dispatch = useDispatchWithResult();
  const loading = useLoading(fetchOne.typePrefix);
  const loadingUpdate = useLoading(updateOne.typePrefix, insertOne.typePrefix);

  useEffect(() => {
    if (id === undefined)
      return;
    if (id === '+')
      dispatch(newOne());
    else
      dispatch(fetchOne(id));
  }, [id]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(ferramenta);
  }, [ferramenta]);

  const title = id === '+' ? 'Nova Ferramenta' : (id && ferramenta.nome) ? ferramenta.nome : <>&nbsp;</>;

  const footer = (
      <>
        <ActionButton.Save
            onClick={() => form.submit()}
            loading={loadingUpdate}
            disabled={loading}
        />
        <ActionButton.Close
            onClick={() => history.push('/ferramentas')}
            disabled={loadingUpdate}
        />
      </>
  );

  const handleFinish = values => {
    if (id === '+') {
      return dispatch(insertOne(values), form)
          .then(payload => history.replace('/ferramentas/' + payload.id));
    } else {
      return dispatch(updateOne({...values, id}), form)
          .then(() => history.replace('/ferramentas/' + id));
    }
  };

  return (
      <MiniFormDrawer
          drawerTitle={title}
          drawerFooter={footer}
          drawerVisible={id !== undefined}
          drawerWidth={500}
          form={form}
          labelFlex='80px'
          loading={loading}
          onDrawerAfterClose={() => dispatch(clearOne())}
          onFinish={handleFinish}
      >
        <h4>Cadastro</h4>
        <Form.Item label="Nome" name="nome">
          <Input maxLength={50} disabled={loadingUpdate} autoFocus />
        </Form.Item>
        <Form.Item name="publicado" valuePropName="checked" className={styles['tail-item']}>
          <Checkbox>Publicado</Checkbox>
        </Form.Item>
        {(id !== '+') &&
        <>
          <Form.Item label="Criado em" name="dataCriacao">
            <DatePicker showTime placeholder="" format='DD/MM/YYYY HH:mm:ss' disabled />
          </Form.Item>
          <h4>Artefatos</h4>
          <Artefatos ferramentaId={id} />
        </>
        }
      </MiniFormDrawer>
  );
}

function Artefato({arquitetura, versao}) {
  const color = arquitetura === 'x86' ? 'purple' : 'geekblue';
  return (
      <Tag color={color} className={styles['artefato']}>{versao}<small>{arquitetura}</small></Tag>
  );
}

const columns = [
  {
    title: 'Nome',
    dataIndex: 'nome',
    key: 'nome',
    width: 160,
    render: (text, record, index) => <Link to={`/ferramentas/${record.id}`}>{text ?? '(não preenchido)'}</Link>,
  },
  {
    title: 'Artefatos',
    key: 'artefatos',
    render: (text, record) => record.artefatos.map(artefato => <Artefato key={artefato.arquitetura} {...artefato} />),
  },
];

export default function() {
  const dispatch = useDispatchWithResult();
  const dataSource = useSelector(dataSourceSelector);
  const selectedRowKeys = useSelector(selectedRowKeysSelector);
  const loading = useLoading(fetch.typePrefix);
  const loadingRemoveSelectedRowKeys = useLoading(removeSelectedRowKeys.typePrefix);
  const params = useParams();

  useEffect(() => {
    dispatch(fetch());
    return () => dispatch(clear());
  }, []);

  return (
      <Page>
        <ActionBar onSearch={s => dispatch(search(s))} searchLoading={loading}>
          <ActionButton.New to="/ferramentas/+" />
          <ActionButton.Remove
              disabled={selectedRowKeys.length === 0}
              onClick={() => dispatch(removeSelectedRowKeys())}
              loading={loadingRemoveSelectedRowKeys}
          />
        </ActionBar>
        <MiniTable
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            rowClassName={record => record.publicado ? undefined : 'inactive'}
            rowSelection={{
              selectedRowKeys,
              onChange: selectedRowKeys => dispatch(onRowSelectionChange(selectedRowKeys)),
            }}
            style={{flexGrow: 1}}
        />
        <Ferramenta id={params.id} />
      </Page>
  );
}
