import {setTwoToneColor} from '@ant-design/icons';
import {ConfigProvider} from 'antd';
import 'antd/dist/antd.less';
import ptBR from 'antd/es/locale/pt_BR';
import moment from 'moment';
import 'moment/locale/pt-br';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import Router from './common/router';
import store from './common/store';
import './global.less';

setTwoToneColor('#7e70eb');

moment.locale('pt-br');

ReactDOM.render(
    <Provider store={store}>
      <ConfigProvider locale={ptBR} componentSize="small">
        <Router />
      </ConfigProvider>
    </Provider>,
    document.getElementById('root'));

if (module.hot) {
  module.hot.accept();
}
