import {useDispatch} from 'react-redux';

export default function useDispatchWithResult() {
  const dispatch = useDispatch();
  return (action, form) => {
    if (typeof form === 'object')
      form.setFields(form.getFieldsError().map(({name}) => ({name, errors: []})));
    const result = dispatch(action);
    if (result instanceof Promise)
      return result
          .then(({payload, error}) => {
            if (error) {
              if (payload) {
                if ((typeof form === 'object') && (typeof payload.errors === 'object')) {
                  form.setFields(Object.entries(payload.errors)
                      .map(([name, errors]) => ({name, errors})));
                }
                return Promise.reject(payload);
              }
              return Promise.reject(error);
            }
            return Promise.resolve(payload);
          });
    return Promise.resolve();
  };
}
