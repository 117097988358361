import moment from 'moment';

export function formatCNPJ(cnpj) {
  cnpj = cnpj?.trim();
  if (!cnpj)
    return;
  if (cnpj.length === 14) {
    return (
        cnpj.substr(0, 2) + '.' +
        cnpj.substr(2, 3) + '.' +
        cnpj.substr(5, 3) + '/' +
        cnpj.substr(8, 4) + '-' +
        cnpj.substr(12, 2));
  }
  return cnpj;
}

export function formatShortDateTime(date) {
  if (date === null || date === undefined)
    return '';
  if (!moment.isMoment(date))
    date = moment.utc(date).local();
  return date.format('DD/MM HH:mm');
}
