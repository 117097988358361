import {createSlice} from '@reduxjs/toolkit';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';

export const slice = createSlice({
  name: 'loading',
  initialState: {},
  reducers: {},
  extraReducers: builder => {
    builder
        .addMatcher(
            action => action.type.endsWith('/pending'),
            (state, action) => {
              const typePrefix = action.type.substring(0, action.type.length - 8);
              state[typePrefix] = true;
            })
        .addMatcher(
            action => action.type.endsWith('/fulfilled'),
            (state, action) => {
              const typePrefix = action.type.substring(0, action.type.length - 10);
              state[typePrefix] = false;
            },
        )
        .addMatcher(
            action => action.type.endsWith('/rejected'),
            (state, action) => {
              const typePrefix = action.type.substring(0, action.type.length - 9);
              state[typePrefix] = false;
            },
        );
  },
});

export function useGlobalLoading() {
  return useSelector(
      state => Object.values(state.loading)
          .some(loading => loading === true));
}

export function useLoading() {
  const types = useMemo(() => Array.from(arguments), [arguments]);
  return useSelector(
      state => Object.entries(state.loading)
          .filter(([_, loading]) => loading === true)
          .some(([typePrefix]) => types.includes(typePrefix)));
}

export default slice.reducer;
