import {Spin} from 'antd';
import React from 'react';
import SimpleLayout from '../layout/simple';
import styles from './_styles.less';

export default function() {
  return (
      <SimpleLayout>
        <div className={styles['centered']}>
          <Spin size="large" />
        </div>
      </SimpleLayout>
  );
};
