import {Checkbox, Col, DatePicker, Form, Input, InputNumber, Row} from 'antd';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link, useHistory, useParams} from 'react-router-dom';
import ActionBar from '../../common/components/action-bar';
import ActionButton from '../../common/components/action-button';
import MiniFormDrawer from '../../common/components/mini-form-drawer';
import MiniTable from '../../common/components/mini-table';
import Page from '../../common/components/page';
import useDispatchWithResult from '../../common/hooks/useDispatchWithResult';
import {useLoading} from '../../common/reducers/loading';
import {formatCNPJ} from '../../common/utils/formatter';
import {
  clear,
  clearOne,
  dataSourceSelector,
  empresaFormSelector,
  fetch,
  fetchInstaladores,
  fetchOne,
  fetchServicos,
  insertOne,
  instaladoresSelector,
  newOne,
  onRowSelectionChange,
  removeSelectedRowKeys,
  search,
  selectedRowKeysSelector,
  servicosSelector,
  updateOne,
} from './reducer';
import styles from './styles.less';

const gutter = [8, 0];

function Empresa(props) {
  const {id} = props;
  const history = useHistory();
  const [form] = Form.useForm();
  const empresa = useSelector(empresaFormSelector);
  const instaladores = useSelector(instaladoresSelector);
  const servicos = useSelector(servicosSelector);
  const dispatch = useDispatchWithResult();
  const loading = useLoading(fetchOne.typePrefix);
  const loadingUpdate = useLoading(updateOne.typePrefix, insertOne.typePrefix);

  useEffect(() => {
    if (id === undefined)
      return;
    if (id === '+')
      dispatch(newOne());
    else
      dispatch(fetchOne(id));
  }, [id]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(empresa);
  }, [empresa]);

  const title = id === '+' ? 'Nova Empresa' : (id && empresa.cnpj) ? formatCNPJ(empresa.cnpj) + ' - ' + empresa.nome : <>&nbsp;</>;

  const footer = (
      <>
        <ActionButton.Save
            onClick={() => form.submit()}
            loading={loadingUpdate}
            disabled={loading}
        />
        <ActionButton.Close
            onClick={() => history.push('/empresas')}
            disabled={loadingUpdate}
        />
      </>
  );

  const handleFinish = values => {
    if (id === '+') {
      return dispatch(insertOne(values), form)
          .then(payload => history.replace('/empresas/' + payload.id));
    } else {
      return dispatch(updateOne({...values, id}), form)
          .then(() => history.replace('/empresas/' + id));
    }
  };

  return (
      <MiniFormDrawer
          drawerTitle={title}
          drawerFooter={footer}
          drawerVisible={id !== undefined}
          drawerWidth={500}
          form={form}
          labelFlex='128px'
          loading={loading}
          onDrawerAfterClose={() => dispatch(clearOne())}
          onFinish={handleFinish}
      >
        <h4>Cadastro</h4>
        <Row gutter={gutter}>
          <Col flex="300px">
            <Form.Item label="CNPJ" name="cnpj">
              <Input maxLength={14} disabled={loadingUpdate} autoFocus />
            </Form.Item>
          </Col>
          <Col flex="80px">
            <Form.Item name="ativo" valuePropName="checked">
              <Checkbox>Ativo</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Nome" name="nome">
          <Input maxLength={100} disabled={loadingUpdate} />
        </Form.Item>
        <Form.Item label="Chave" name="chave">
          <Input maxLength={36} disabled={loadingUpdate} />
        </Form.Item>
        <Form.Item label="Logo (base64)" name="logo">
          <Input.TextArea autoSize={{minRows: 2, maxRows: 2}} disabled={loadingUpdate} />
        </Form.Item>
        {(id !== '+') &&
        <Form.Item label="Criado em" name="dataCriacao">
          <DatePicker showTime placeholder="" format='DD/MM/YYYY HH:mm:ss' disabled />
        </Form.Item>
        }
        <h4>Servidor</h4>
        <Form.Item label="Host" name="servidorHost">
          <Input maxLength={100} disabled={loadingUpdate} />
        </Form.Item>
        <Row gutter={gutter}>
          <Col flex="220px">
            <Form.Item label="Porta" name="servidorPorta">
              <InputNumber maxLength={5} disabled={loadingUpdate} />
            </Form.Item>
          </Col>
          <Col flex="1">
            <Form.Item name="servidorSsl" valuePropName="checked">
              <Checkbox>SSL</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <h4>Licença</h4>
        <Row gutter={gutter}>
          <Col flex="220px">
            <Form.Item label="Procyon" name="licencaProcyonUsuarios">
              <InputNumber maxLength={5} disabled={loadingUpdate} />
            </Form.Item>
          </Col>
          <Col flex="1">usuário(s)</Col>
        </Row>
        <Row gutter={gutter}>
          <Col flex="220px">
            <Form.Item label="ProcyonServices" name="licencaProcyonServicesUsuarios">
              <InputNumber maxLength={5} disabled={loadingUpdate} />
            </Form.Item>
          </Col>
          <Col flex="1">usuário(s)</Col>
        </Row>
        <Row gutter={gutter}>
          <Col flex="220px">
            <Form.Item label="ProcyonManager" name="licencaProcyonManagerUsuarios">
              <InputNumber maxLength={5} disabled={loadingUpdate} />
            </Form.Item>
          </Col>
          <Col flex="1">usuário(s)</Col>
        </Row>
        <Row gutter={gutter}>
          <Col flex="220px">
            <Form.Item label="SmartSped" name="licencaSmartSpedCnpjs">
              <InputNumber maxLength={5} disabled={loadingUpdate} />
            </Form.Item>
          </Col>
          <Col flex="1">CNPJ(s)</Col>
        </Row>
        <Row gutter={gutter}>
          <Col flex={1}>
            <h4>Instaladores</h4>
            <Form.Item label="Instaladores" name="instaladores" noStyle>
              <Checkbox.Group options={instaladores} className={styles['checkbox-group']} />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <h4>Serviços</h4>
            <Form.Item label="Serviços" name="servicos" noStyle>
              <Checkbox.Group options={servicos} className={styles['checkbox-group']} />
            </Form.Item>
          </Col>
        </Row>
      </MiniFormDrawer>
  );
}

const columns = [
  {
    title: 'CNPJ',
    dataIndex: 'cnpj',
    key: 'cnpj',
    width: 140,
    render: (text, record, index) => <Link to={`/empresas/${record.id}`}>{formatCNPJ(text) ?? '(não preenchido)'}</Link>,
  },
  {
    title: 'Nome',
    dataIndex: 'nome',
    key: 'nome',
  },
];

export default function() {
  const dispatch = useDispatchWithResult();
  const dataSource = useSelector(dataSourceSelector);
  const selectedRowKeys = useSelector(selectedRowKeysSelector);
  const loading = useLoading(fetch.typePrefix);
  const loadingRemoveSelectedRowKeys = useLoading(removeSelectedRowKeys.typePrefix);
  const params = useParams();

  useEffect(() => {
    dispatch(fetch());
    dispatch(fetchInstaladores());
    dispatch(fetchServicos());
    return () => dispatch(clear());
  }, []);

  return (
      <Page>
        <ActionBar onSearch={s => dispatch(search(s))} searchLoading={loading}>
          <ActionButton.New to="/empresas/+" />
          <ActionButton.Remove
              disabled={selectedRowKeys.length === 0}
              onClick={() => dispatch(removeSelectedRowKeys())}
              loading={loadingRemoveSelectedRowKeys}
          />
        </ActionBar>
        <MiniTable
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            rowClassName={record => record.ativo ? undefined : 'inactive'}
            rowSelection={{
              selectedRowKeys,
              onChange: selectedRowKeys => dispatch(onRowSelectionChange(selectedRowKeys)),
            }}
            style={{flexGrow: 1}}
        />
        <Empresa id={params.id} />
      </Page>
  );
}
