// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2ASfGnk0ggVuVIWPn9pa8B > * {\n  display: block;\n}\n.waRIi6rKr9z1WiA_tf56x {\n  padding-left: 128px;\n  margin-left: 14px;\n}\n._2ywE5-VeTiyxwIwTnhKJX {\n  min-width: 90px;\n  text-align: right;\n  font-weight: bold;\n}\n._2ywE5-VeTiyxwIwTnhKJX > small {\n  margin-left: 4px;\n}\n._2ywE5-VeTiyxwIwTnhKJX:hover {\n  cursor: pointer;\n}\n._2McMNAoPgGrPn1uvctRt-- {\n  padding-left: 80px;\n}\n._1n2Th1jKg9VyF-cCIRCDJr {\n  font-size: 18pt;\n}\n", "",{"version":3,"sources":["webpack://src/features/instaladores/artefatos/styles.less"],"names":[],"mappings":"AAAA;EAEI,cAAA;AAAJ;AAIA;EACE,mBAAA;EACA,iBAAA;AAFF;AAKA;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;AAHF;AAAA;EAMI,gBAAA;AAHJ;AAME;EACE,eAAA;AAJJ;AAQA;EACE,kBAAA;AANF;AASA;EACE,eAAA;AAPF","sourcesContent":[".checkbox-group {\n  > * {\n    display: block;\n  }\n}\n\n.footer {\n  padding-left: 128px;\n  margin-left: 14px;\n}\n\n.artefato {\n  min-width: 90px;\n  text-align: right;\n  font-weight: bold;\n\n  > small {\n    margin-left: 4px;\n  }\n\n  &:hover {\n    cursor: pointer;\n  }\n}\n\n.tail-item {\n  padding-left: 80px;\n}\n\n.dragger-icon {\n  font-size: 18pt;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox-group": "_2ASfGnk0ggVuVIWPn9pa8B",
	"footer": "waRIi6rKr9z1WiA_tf56x",
	"artefato": "_2ywE5-VeTiyxwIwTnhKJX",
	"tail-item": "_2McMNAoPgGrPn1uvctRt--",
	"dragger-icon": "_1n2Th1jKg9VyF-cCIRCDJr"
};
export default ___CSS_LOADER_EXPORT___;
